import PageLinkComponent from '@/components/page/linkComponent';
import SettingsButton from '@/pages/dashboard/main/settingsButton';
import useGetPartnerDashboardTabs from '@/pages/partner/dashboard/dashboardTabs';
import { AppBar, Box, Button, Container, Stack, useTheme } from '@mui/material';
import { useRouter } from 'next/router';
import React, { Fragment, ReactNode } from 'react';

export default function PartnerDashboardLayout( { children }: { children: ReactNode } ) {
	const tabs = useGetPartnerDashboardTabs();
	const router = useRouter();
	const theme = useTheme();
	
	return (
		<Fragment>
			<AppBar
				color='inherit'
				position='static'
				sx={{
					border      : 0,
					borderBottom: 1,
					borderColor : 'divider',
					pt          : 1,
					pb          : 1,
					bgcolor     : 'transparent',
					boxShadow   : 'none !important',
					
				}}>
				<Container maxWidth='xl'>
					<Stack spacing={1} direction='row' sx={{ display: 'flex', flexGrow: 1 }}>
						{tabs?.map( ( tab, index ) => {
							const selectedRoute = router.pathname === tab.href || router.pathname.startsWith( tab.href ) && tab.href !== '/partner/dashboard';
							console.log( 'selectedRoute', selectedRoute );
							return (
								<Button
									key={index}
									color={selectedRoute ? 'primary' : 'alpha'}
									className='AppbarButton'
									sx={{
										'height'      : 27,
										'boxShadow'   : 'none !important',
										'border'      : '0px !important',
										'transition'  : '.4s all',
										'borderRadius': '15px !important',
										'color'       : selectedRoute && '#ffffff !important',
										'bgcolor'     : selectedRoute
											? `${theme.palette.primary.main} !important`
											: 'transparent !important',
										':hover'      : { bgcolor: !selectedRoute && `${theme.palette.divider} !important` },
									}}
									component={PageLinkComponent}
									href={tab.href}>
									{tab.name}
								</Button>
							);
						} )}
						<Box display='flex' flexGrow={1}/>
						<SettingsButton title='Profile & Company Settings' href='/partner/settings'/>
					</Stack>
				</Container>
			</AppBar>
			{children}
		</Fragment>
	);
}

