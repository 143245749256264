import PageLinkComponent from '@/components/page/linkComponent';
import { Box, Button } from '@mui/material';
import { useRouter } from 'next/router';
import { Fragment } from 'react';

export default function AuthButtons() {
	const router = useRouter();
	
	return (
		<Fragment>
			<Box display='flex' flexGrow={1}/>
			<Button
				color='primary'
				variant='text'
				component={PageLinkComponent}
				href={`/login?redirect=${router.asPath}`}>
				Login
			</Button>
			<Button
				color='primary'
				sx={{ borderRadius: '20px !important' }}
				component={PageLinkComponent}
				href={`/signup?redirect=${router.asPath}`}>
				Sign Up
			</Button>
		</Fragment>
	);
}
