import { auth } from '@/firebase/client';
import { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useIntervalWhen } from 'rooks';

const EMERGENCY_BANNER_HOST = process.env.NEXT_PUBLIC_EMERGENCY_BANNER_HOST;

function EmergencyBannerIframe() {
	const [ height, setHeight ] = useState( 0 );
	const [ refreshKey, setRefreshKey ] = useState( 0 );
	
	useIntervalWhen( () => {
		setRefreshKey( refreshKey + 1 );
	}, 30000 ); // Refresh the iframe every 30 seconds
	
	function receiveIframeMessage( event ) {
		if ( event.origin === EMERGENCY_BANNER_HOST ) {
			setHeight( event.data.height );
		}
	}
	
	useEffect( () => {
		window.addEventListener( 'message', receiveIframeMessage );
		return () => {
			window.removeEventListener( 'message', receiveIframeMessage );
		};
	}, [] );
	
	return (
		<iframe
			key={refreshKey}
			// src={`${EMERGENCY_BANNER_HOST}?theme=${theme}`}
			src={EMERGENCY_BANNER_HOST}
			style={{ display: 'flex', width: '100%', border: 'none', height }}
		/>
	);
}

export default function Wrapper() {
	const [ firebaseUser ] = useAuthState( auth );
	// // warning a mobile crash
	// const isNotMobile = useMediaQuery( '(min-width:900px)' );
	
	if ( !firebaseUser ) return null; // not showing the banner if the user is not logged in
	return <EmergencyBannerIframe/>;
}
