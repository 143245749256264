import { useGraphQL } from '@/data';
import { PartnerRead } from '@/data/partner.graphql';
import { usePartnerId } from '@/providers/auth/useSessionStoragePartnerId';
import { useEvents } from '@/providers/event';
import { QueryPartnerReadArgs } from '@/types/schema';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';

export function usePartner() {
	const { sessionPartnerId } = usePartnerId();
	const queryClient = useQueryClient();
	const appEvent = useEvents();
	
	const { data, isLoading } = useGraphQL<QueryPartnerReadArgs>( {
		query    : PartnerRead,
		queryKey : [ 'partner' ],
		variables: { id: sessionPartnerId as string },
	}, { enabled: sessionPartnerId !== 'null' && Boolean( sessionPartnerId ) } );
	
	useEffect( () => {
		if ( !sessionPartnerId ) return;
		const event = `SUBSCRIPTION:${sessionPartnerId}`;
		const listener = () => {
			queryClient.invalidateQueries( [ 'partner' ] );
		};
		appEvent.on( event, listener );
		
		return () => {
			appEvent.off( event, listener );
		};
	}, [ sessionPartnerId ] );
	
	return { partner: data?.partnerRead, loading: isLoading };
}
