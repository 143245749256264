import PageLink from '@/components/page/link';
import useUserInfo from '@/providers/auth/useUserInfo';
import { Stack, Typography } from '@mui/material';
import { useAtom } from 'jotai/index';
import React from 'react';
import safeDateFns from '../../../../helpers/safeFormat';
import useGetDeviceInfo from '../../../../hooks/useGetDeviceInfo';
import { dismissibleBannerAtoms } from '../../../../utils/atoms';
import GlobalBannerWrapper from './wrapper';

export default function ScheduleTrainingBanner() {
	const { user, staff } = useUserInfo();
	const { isCloverDevice } = useGetDeviceInfo();
	
	const [ globalBanners, setGlobalBanners ] = useAtom( dismissibleBannerAtoms );
	
	return (
		<GlobalBannerWrapper
			show={!globalBanners.hideScheduleTrainingBanner
				&& ( safeDateFns.isSameDay( staff?.company?.createdAt, new Date() ) || staff?.company?.companyCountMetric?.invoiceCount < 3 )
				&& !isCloverDevice
				&& Boolean( staff )}
			onClose={() => setGlobalBanners( ( prev ) => ( { ...prev, hideScheduleTrainingBanner: true } ) )}>
			<Stack
				direction={{ xs: 'column', lg: 'row' }}
				alignItems='center'
				justifyContent='center'>
				<div/>
				<Typography variant='h5' sx={{ textAlign: 'center', fontWeight: 'normal', color: 'primary.main' }}>
					Welcome to Invoiss {user?.firstName}! Explore our platform and schedule a training session with one
					of our specialists to discover the capabilities of Invoiss in just 30 minutes.
				</Typography>
				<PageLink
					sx={{ pl: 1, color: 'warning.main' }}
					href='https://calendly.com/invoiss/invoiss-introduction'
					target='_blank'>
					SCHEDULE TRAINING
				</PageLink>
			</Stack>
		</GlobalBannerWrapper>
	);
}

