import AsyncLoadingButton from '@/components/form/asyncLoading/asyncLoadingButton';
import { ResponsiveModalContainer } from '@/providers/modal/responsiveModal';
import { Gateway, LineItem, Location } from '@/types/schema';
import { Box, Button, Step, StepContent, StepLabel, Stepper, Typography } from '@mui/material';
import { isEmpty } from 'lodash-es';
import { useState } from 'react';
import PurchaseForVendor from './purchaseForVendor';
import SelectLocation from './selectLocation';
import UpdateReceiving from './updateReceiving';

export default function PurchaseReceivingCreationModal() {
	const [ step, setStep ] = useState( 0 );
	const [ location, setLocation ] = useState<Location>( null );
	const [ lineItems, setLineItems ] = useState<LineItem[]>( [] );
	
	return (
		<ResponsiveModalContainer title='Purchase Order'>
			<Stepper orientation='vertical' activeStep={step}>
				<Step key={0}>
					<StepLabel>
						<Typography variant='h5'>
							Select Location
						</Typography>
					</StepLabel>
					<StepContent TransitionProps={{ unmountOnExit: false }} sx={{ width: 300 }}>
						<SelectLocation
							goToNextStep={( location ) => {
								setStep( 1 );
								setLocation( location );
							}}
						/>
					</StepContent>
				</Step>
				<Step key={1}>
					<StepLabel>
						<Typography variant='h5'>
							Purchase Receiving
						</Typography>
					</StepLabel>
					<StepContent TransitionProps={{ unmountOnExit: false }}>
						<UpdateReceiving
							gateway={location?.gateway as Gateway}
							lineItems={lineItems}
							setLineItems={setLineItems}
						/>
						<Box sx={{ my: 1 }}>
							<Button variant='outlined' onClick={() => setStep( 0 )}>
								Back
							</Button>
							<AsyncLoadingButton
								variant='contained'
								color='primary'
								sx={{ ml: 1 }}
								disabled={isEmpty( lineItems )}
								onClick={() => {
									setStep( 2 );
								}}>
								Continue
							</AsyncLoadingButton>
						</Box>
					</StepContent>
				</Step>
				<Step key={2}>
					<StepLabel>
						<Typography variant='h5'>
							Update received stocks
						</Typography>
					</StepLabel>
					<StepContent>
						<PurchaseForVendor
							lineItems={lineItems}
							location={location}
							goToPreviousStep={() => setStep( 1 )}
						/>
					</StepContent>
				</Step>
			</Stepper>
		</ResponsiveModalContainer>
	);
}
