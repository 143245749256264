import {
	Close as CloseIcon,
	ExpandLess as ExpandLessIcon,
	ExpandMore as ExpandMoreIcon,
	FitScreenRounded as FullScreenIcon,
} from '@mui/icons-material';
import { IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { useAtom } from 'jotai/index';
import { useRouter } from 'next/router';
import { chatSettingsAtom } from '../../utils/atoms';

export default function ChatBoxToolbar( { clientView = false } ) {
	const [ chatSettings, setChatSettings ] = useAtom( chatSettingsAtom );
	const router = useRouter();
	
	return (
		<Stack
			direction='row'
			alignItems='center'
			justifyContent='space-between'
			sx={{
				p           : 1,
				borderBottom: chatSettings.minimizeChat ? 0 : 1,
				borderColor : 'divider',
				cursor      : 'pointer',
			}}
			onClick={() => setChatSettings( ( prevSetting ) => ( {
				...prevSetting,
				minimizeChat: !prevSetting.minimizeChat,
			} ) )}>
			<Typography variant='h3'>Messages</Typography>
			<Stack direction='row' spacing={1} sx={{ mt: 0 }}>
				<Tooltip title='Enter Full Screen'>
					{!chatSettings.minimizeChat && !router.pathname.includes( '/chat' ) && (
						<IconButton
							onClick={async ( e ) => {
								e.stopPropagation();
								const roomId = chatSettings.chatRoomID;
								const url = clientView ? `/client/chat/${roomId}` : `/dashboard/chat/${roomId}`;
								await router.push( url );
								
								setChatSettings( ( prevSetting ) => ( {
									...prevSetting,
									minimizeChat: false,
									windowChat  : false,
									chatRoomID  : '',
								} ) );
							}}>
							<FullScreenIcon/>
						</IconButton>
					)}
				</Tooltip>
				<IconButton
					onClick={( e ) => {
						e.stopPropagation();
						setChatSettings( ( prevSetting ) => ( {
							...prevSetting,
							minimizeChat: !prevSetting.minimizeChat,
						} ) );
					}}>
					{!chatSettings.minimizeChat ? <ExpandMoreIcon/> : <ExpandLessIcon/>}
				</IconButton>
				<Tooltip title='Close'>
					<IconButton
						onClick={( e ) => {
							e.stopPropagation();
							setChatSettings( ( prevSetting ) => ( {
								...prevSetting,
								windowChat: false,
							} ) );
						}}>
						<CloseIcon/>
					</IconButton>
				</Tooltip>
			</Stack>
		</Stack>
	);
}
