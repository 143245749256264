import ConditionalSkeleton from '@/components/animations/conditionalSkeleton';
import { blurryBackground } from '@/components/elevationScroll';
import FormattedTextField from '@/components/formattedTextField';
import PageLinkComponent from '@/components/page/linkComponent';
import GlobalSearchSkeleton from '@/components/skeletons/globalSearchSkeleton';
import { useModalControls } from '@/providers/modal';
import {
	AccountCircleRounded as AccountCircleRoundedIcon,
	FindInPageRounded as FindInPageRoundedIcon,
	InventoryRounded as InventoryRoundedIcon,
	LocalOfferRounded as LocalOfferRoundedIcon,
	ReceiptRounded as ReceiptRoundedIcon,
	SearchOffRounded as SearchOffRoundedIcon,
	SearchRounded as SearchRoundedIcon,
} from '@mui/icons-material';
import {
	Box,
	DialogActions,
	DialogContent,
	DialogTitle,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Stack,
	Typography,
} from '@mui/material';
import { Fragment, useState } from 'react';
import { useDebounce } from 'rooks';
import { useGraphQL } from '../../data';
import { GlobalSearch } from '../../data/management/search.graphql';
import SearchModalFooter from './searchModalFooter';

interface Item {
	id: string,
	name: string,
	description?: string,
	email?: string,
	type?: string,
	title?: string,
	number?: string
}

interface SearchResults {
	items: Item[],
	count: number
}

interface GlobalSearchData { // TODO: there is a problem with this type when used. So I added any for now.
	itemsSearchRead?: SearchResults,
	clientsSearchRead?: SearchResults,
	ordersSearchRead?: SearchResults,
	purchaseSearchRead?: SearchResults
}

export default function GlobalSearchModal() {
	const [ searchValue, setSearchValue ] = useState( '' );
	const { closeModal } = useModalControls();
	
	const handleSearchChange = useDebounce( ( e ) => setSearchValue( e.target.value ), 250 );
	
	const { data, isFetching } = useGraphQL<any>( {
			query    : GlobalSearch,
			queryKey : [ 'globalSearch' ],
			variables: {
				options: {
					search: searchValue,
				},
			},
		},
		{ keepPreviousData: true, enabled: Boolean( searchValue.length ) },
	);
	
	const getSectionTitle = ( type: any ) => {
		const titles = {
			itemsSearchRead    : 'Items',
			clientsSearchRead  : 'Clients',
			ordersSearchRead   : 'Orders',
			purchasesSearchRead: 'Purchases',
		};
		
		return titles[ type ] || 'Search Results';
	};
	
	const renderItem = ( type: any, item: any, closeModal: any ) => {
		const href = {
			itemsSearchRead    : `/dashboard/management/items/${item.id}`,
			clientsSearchRead  : `/dashboard/management/clients/${item.id}`,
			ordersSearchRead   : `/dashboard/commerce/${item.type?.toLowerCase()}s/${item.id}`,
			purchasesSearchRead: `/dashboard/commerce/purchases/${item.id}`,
		}[ type ];
		
		const primaryText = {
			itemsSearchRead    : item.name,
			clientsSearchRead  : item.name || item.contact || 'No name (client)',
			ordersSearchRead   : item.type + '  #' + item.number,
			purchasesSearchRead: '#' + item.number,
		}[ type ];
		
		const secondaryText = {
			itemsSearchRead    : `Description: ${item.description || '-'}`,
			clientsSearchRead  : `Email: ${item.email || '-'}`,
			ordersSearchRead   : `Client: ${item.client?.name || '-'}`,
			purchasesSearchRead: `Vendor: ${item.menu?.vendorName || '-'}`,
		}[ type ];
		
		const Icon = {
			itemsSearchRead    : LocalOfferRoundedIcon,
			clientsSearchRead  : AccountCircleRoundedIcon,
			ordersSearchRead   : ReceiptRoundedIcon,
			purchasesSearchRead: InventoryRoundedIcon,
		}[ type ];
		
		return (
			<ListItemButton key={item.id} component={PageLinkComponent} href={href} onClick={closeModal}>
				<ListItemIcon sx={{ minWidth: 35 }}><Icon/></ListItemIcon>
				<ListItemText
					primary={primaryText}
					secondary={secondaryText}
					primaryTypographyProps={{
						sx: {
							whiteSpace     : 'nowrap',
							overflow       : 'hidden',
							textOverflow   : 'ellipsis',
							WebkitLineClamp: 1,
						},
					}}
					secondaryTypographyProps={{
						sx: {
							whiteSpace     : 'nowrap',
							overflow       : 'hidden',
							textOverflow   : 'ellipsis',
							WebkitLineClamp: 1,
						},
					}}
				/>
			</ListItemButton>
		);
	};
	
	return (
		<Fragment>
			<DialogTitle>
				<FormattedTextField
					autoFocus
					fullWidth
					value={searchValue}
					variant='standard'
					size='medium'
					placeholder='Search'
					InputProps={{
						disableUnderline: true,
						startAdornment  : <SearchRoundedIcon
							fontSize='medium'
							sx={{ color: 'text.secondary', marginRight: 1 }}
						/>,
					}}
					sx={{ '.MuiInputBase-root': { fontSize: 22 } }}
					onChange={handleSearchChange}
				/>
			</DialogTitle>
			<DialogContent sx={{ p: 0, height: { sm: window.innerHeight * 0.5, xs: '700px' } }}>
				<ConditionalSkeleton
					isLoading={isFetching}
					skeleton={(
						<Box mt={3} px={2}>
							<GlobalSearchSkeleton/>
						</Box>
					)}>
					{searchValue?.length === 0 ? (
						<Stack alignItems='center' spacing={1} pt={15}>
							<FindInPageRoundedIcon sx={{ fontSize: 100, color: 'text.secondary' }}/>
							<Typography color='text.secondary'>Begin Search</Typography>
						</Stack>
					) : !Object.values( data ).some( ( value: any ) => value?.items?.length > 0 ) && !isFetching ? (
						<Stack alignItems='center' spacing={1} pt={15}>
							<SearchOffRoundedIcon sx={{ fontSize: 100, color: 'text.secondary' }}/>
							<Typography color='text.secondary'>
								No Search Results for
								&nbsp;<strong>"{searchValue}"</strong>
							</Typography>
						</Stack>
					) : Object.entries( data ).map( ( [ key, value ]: any ) => {
						if ( value?.items?.length ) {
							return (
								<Stack key={key}>
									<Typography
										variant='h6'
										fontWeight={500}
										color='text.secondary'
										sx={{
											position: 'sticky',
											zIndex  : 2,
											top     : 0,
											bgcolor : 'background.paper',
											py      : 1,
											px      : 2,
											width   : '100%',
											...blurryBackground(),
										}}>
										{getSectionTitle( key )}
									</Typography>
									<Box px={2}>
										{value.items.map( ( item ) => renderItem( key, item, closeModal ) )}
									</Box>
								</Stack>
							);
						}
						return null;
					} )}
				</ConditionalSkeleton>
			</DialogContent>
			<DialogActions sx={{ justifyContent: 'unset' }}>
				<SearchModalFooter/>
			</DialogActions>
		</Fragment>
	);
}
