import { falseMenuItemsObj, useGetFullMenu } from '@/baseline/navigation/menuItems';
import ChatRoomComponent from '@/components/chat/chatRoomComponent';
import PageLinkComponent from '@/components/page/linkComponent';
import useAccountType from '@/helpers/useAccountType';
import useGetDeviceInfo from '@/hooks/useGetDeviceInfo';
import DriversButton from '@/pages/dashboard/main/driversButton';
import useUserInfo from '@/providers/auth/useUserInfo';
import { usePopper } from '@/providers/popper';
import { CompleteSplash } from '@/providers/splash';
import { isSuperAdmin } from '@/utils/isSuperAdmin';
import {
	KeyboardArrowDownRounded as KeyboardArrowDownRoundedIcon,
	MoreHoriz as MoreHorizIcon,
	TuneRounded as TuneRoundedIcon,
} from '@mui/icons-material';
import {
	AppBar,
	Box,
	Button,
	Container,
	Grid,
	IconButton,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Stack,
	SvgIcon,
	Theme,
	Tooltip,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { kebabCase } from 'lodash-es';
import { useRouter } from 'next/router';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ActivityLogButton from './main/activityLog';
import ChatButton from './main/chatButton';
import ConnectViaWeb from './main/connectViaWeb';
import SettingsButton from './main/settingsButton';
import QuickActionFloatingButton from './settings/preferences/quickActionFloatingButton';
import TabsMenu from './tabsMenu';

type MenuTabsType = Record<string, boolean>;

export function DashboardLayout( { children } ) {
	const { i18n } = useTranslation();
	const { user, staff } = useUserInfo();
	const router = useRouter();
	const { t } = useTranslation();
	const theme = useTheme();
	const fullMenuItems = useGetFullMenu();
	const { showPopper } = usePopper();
	const isMobile = useMediaQuery<Theme>( ( { breakpoints } ) => breakpoints.down( 'sm' ) );
	const isCloverAccount = useAccountType( 'CLOVER' );
	const { isCloverDevice } = useGetDeviceInfo();
	
	const permissionTabs = fullMenuItems.reduce( ( tabs, item ) => ( {
		...tabs,
		[ item.name ]: true,
	} ), {} as MenuTabsType );
	
	const defaultTabs = Object.keys( falseMenuItemsObj ).reduce( ( tabs, tab ) => {
		if ( permissionTabs[ tab ] ) {
			tabs = { ...tabs, [ tab ]: true };
		} else {
			tabs = { ...tabs, [ tab ]: false };
		}
		return tabs;
	}, {} as MenuTabsType );
	
	const intitalTabs = isSuperAdmin( user )
		? defaultTabs
		: staff?.selectedTabsData?.menuTabs
			? Object.keys( falseMenuItemsObj ).reduce( ( tabs, tab ) => {
				if ( staff.selectedTabsData?.menuTabs[ tab ] ) {
					tabs = { ...tabs, [ tab ]: true };
				} else {
					tabs = { ...tabs, [ tab ]: false };
				}
				return tabs;
			}, {} as MenuTabsType )
			: defaultTabs;
	
	const [ tabs, setTabs ] = useState<MenuTabsType>( intitalTabs );
	
	useEffect( () => {
		if ( user?.language ) {
			i18n.changeLanguage( user.language ).catch( console.error );
		}
	}, [] );
	
	useEffect( () => {
		if ( !user ) router.replace( '/' ).then();
		else if ( user?.salesAgent?.id ) router.replace( '/partner/dashboard' ).then(); // Route the user to the partner dashboard if they are a sales agent
		else if ( !staff && !isSuperAdmin( user ) ) router.replace( '/welcome' );
	}, [ user, staff ] );
	
	const [ outsideOfMenuButtons, menu ] = useMemo( () => {
		const max = 6;
		const filtered = Object.keys( intitalTabs ).map( ( key ) => {
			if ( intitalTabs[ key ] ) {
				return key;
			}
		} ).filter( Boolean );
		
		if ( !max || filtered.length <= max ) return [ filtered, [] ];
		
		const buttons = filtered.slice( 0, max );
		const menu = filtered.slice( max );
		return [ buttons, menu ];
	}, [ tabs, intitalTabs ] );
	
	const isTabOutsideOfMenu = ( route ) => menu.some( ( tabName ) => {
		const menuItem = fullMenuItems.find( ( item ) => item.name === tabName );
		return menuItem && route.includes( menuItem.href );
	} );
	
	if ( !user || !staff && !isSuperAdmin( user ) || user?.salesAgent?.id ) return null;
	if ( !isCloverAccount && isCloverDevice ) return <ConnectViaWeb/>;
	if ( isMobile ) return children;
	
	return (
		<Fragment>
			<CompleteSplash/>
			<QuickActionFloatingButton/>
			<ChatRoomComponent clientView={false}/>
			<AppBar
				color='inherit'
				position='static'
				sx={{
					'border'       : 0,
					'borderBottom' : 1,
					'borderColor'  : 'divider',
					'pt'           : 1,
					'pb'           : 1,
					'bgcolor'      : 'transparent',
					'boxShadow'    : 'none !important',
					'.AppbarButton': {
						height      : 27,
						boxShadow   : 'none !important',
						border      : '0px !important',
						transition  : '.4s all',
						borderRadius: '15px !important',
					},
				}}>
				<Container maxWidth='xl'>
					<Stack spacing={1} direction='row' sx={{ display: 'flex', flexGrow: 1 }}>
						{!isSuperAdmin( user ) && ( staff?.permissions?.includes( 'OWNER' ) || staff?.permissions?.includes( 'ADMIN' ) )
							&& (
								<Tooltip title='Show/Hide Tabs'>
									<IconButton
										onClick={( e ) => showPopper( () => (
											<TabsMenu
												setTabs={setTabs}
												tabs={tabs}
												items={fullMenuItems}
											/>
										), e.currentTarget )}>
										<TuneRoundedIcon/>
									</IconButton>
								</Tooltip>
							)}
						{outsideOfMenuButtons?.map( ( tab, index ) => {
							const currentRoute = fullMenuItems.find( ( item ) => item.name === tab );
							if ( !currentRoute ) return null;
							
							const selectedRoute = currentRoute.href === '/dashboard'
								? router.route === currentRoute.href
								: router.route.includes( currentRoute.href );
							return (
								<Button
									key={index}
									color={selectedRoute ? 'primary' : 'alpha'}
									className='AppbarButton'
									sx={{
										'color'  : selectedRoute && '#ffffff !important',
										'bgcolor': selectedRoute
											? `${theme.palette.primary.main} !important`
											: 'transparent !important',
										':hover' : { bgcolor: !selectedRoute && `${theme.palette.divider} !important` },
									}}
									component={PageLinkComponent}
									href={currentRoute.href}>
									{t( `common:${kebabCase( currentRoute.name.toLowerCase() )}` )}
								</Button>
							);
						} )}
						{menu.length !== 0 && (
							<Button
								className='AppbarButton'
								sx={{
									'color'  : isTabOutsideOfMenu( router.route ) && '#ffffff !important',
									'bgcolor': isTabOutsideOfMenu( router.route )
										? `${theme.palette.primary.main} !important`
										: 'transparent !important',
									':hover' : { bgcolor: !isTabOutsideOfMenu( router.route ) && `${theme.palette.divider} !important` },
								}}
								endIcon={<KeyboardArrowDownRoundedIcon/>}
								onClick={( e ) => showPopper( ( { closePopper } ) => (
									<Grid container width={500} p={1} spacing={1}>
										{menu.map( ( tab, index ) => {
											const currentRoute = fullMenuItems.find( ( item ) => item.name === tab );
											const selectedRoute = currentRoute.href === '/dashboard'
												? router.route === currentRoute.href
												: router.route.includes( currentRoute.href );
											return (
												<Grid key={index} item xs={6}>
													<ListItemButton
														alignItems='flex-start'
														component={PageLinkComponent}
														sx={{
															'bgcolor'   : selectedRoute ? 'alpha.primary' : 'alpha',
															'transition': '.4s',
															':hover'    : {
																bgcolor: selectedRoute ? 'alpha.primary' : 'divider',
															},
														}}
														href={currentRoute.href}
														onClick={() => closePopper()}>
														<ListItemIcon
															sx={{
																minWidth    : 0 + ' !important',
																p           : 1,
																mr          : 1,
																bgcolor     : currentRoute.background,
																borderRadius: 2,
																svg         : {
																	fontSize: '2rem !important',
																	color   : '#ffffff',
																},
															}}>
															<SvgIcon component={currentRoute.Icon}/>
														</ListItemIcon>
														<ListItemText
															primaryTypographyProps={{ fontWeight: 'bold' }}
															primary={t( `common:${currentRoute.name
																.toLowerCase()
																.replace( ' ', '-' )}` )}
															secondary={t( `common:${currentRoute.name
																.toLowerCase()
																.replace( ' ', '-' )}-sub` )}
														/>
													</ListItemButton>
												</Grid>
											);
										} )}
									</Grid>
								), e.currentTarget, { disablePortal: true } )}>
								<MoreHorizIcon/>
							</Button>
						)}
						<Box display='flex' flexGrow={1}/>
						<DriversButton/>
						<ChatButton clientView={false}/>
						<ActivityLogButton/>
						<SettingsButton title='Profile & Company Settings' href='/dashboard/settings'/>
					</Stack>
				</Container>
			</AppBar>
			{children}
		</Fragment>
	);
}
