import { Dispatch, SetStateAction, useEffect, useState } from 'react';

type CustomLocalStorageReturnType<T> = [ T | undefined, Dispatch<SetStateAction<T>>, () => void ];

export default function useCustomLocalStorage<T extends Record<string, any>>( key: string,
	initialValue: T ): CustomLocalStorageReturnType<T> {
	// Function to read value from local storage
	const readValue = (): T => {
		if ( typeof window === 'undefined' ) {
			return initialValue;
		}
		
		try {
			const item = window.localStorage.getItem( key );
			return item ? JSON.parse( item ) : initialValue;
		} catch ( error ) {
			console.warn( `Error reading localStorage key “${key}”:`, error );
			return initialValue;
		}
	};
	
	// State to store our value
	const [ storedValue, setStoredValue ] = useState<T>( readValue );
	
	// Update local storage when the storedValue changes
	useEffect( () => {
		if ( typeof window === 'undefined' ) {
			return;
		}
		
		try {
			const serializedState = JSON.stringify( storedValue );
			window.localStorage.setItem( key, serializedState );
		} catch ( error ) {
			console.warn( `Error setting localStorage key “${key}”:`, error );
		}
	}, [ key, storedValue ] );
	
	// Function to update local storage directly
	const updateLocalStorage: Dispatch<SetStateAction<T>> = ( valueToStore ) => {
		setStoredValue( () => {
			const currentStorageValue = readValue();
			let newValue: T;
			
			if ( valueToStore instanceof Function ) {
				newValue = valueToStore( currentStorageValue );
			} else if ( typeof valueToStore === 'object' && valueToStore !== null ) {
				// Merging new value with current value from localStorage
				newValue = { ...currentStorageValue, ...( valueToStore as object ) };
			} else {
				// Direct value setting, expecting a key-value pair
				newValue = { ...currentStorageValue, [ valueToStore ]: true };
			}
			
			window.localStorage.setItem( key, JSON.stringify( newValue ) );
			return newValue;
		} );
	};
	
	return [ storedValue, updateLocalStorage ];
}
