import AsyncLoadingButton from '@/components/form/asyncLoading/asyncLoadingButton';
import { useGraphQL } from '@/data';
import { GatewaysRead } from '@/data/gateway.graphql';
import { useGraphqlResult } from '@/data/query/graphqlProvider';
import useAccountType from '@/helpers/useAccountType';
import { isEstimateType } from '@/helpers/useIsEstimateType';
import { eligibleForBankTransfer } from '@/pages/dashboard/commerce/payment/helpers';
import type { Order, Store } from '@/types/schema';
import { GatewayBase, QueryGatewaysReadArgs } from '@/types/schema';
import { ArrowBackIos as ArrowBackIosIcon } from '@mui/icons-material';
import { Box, Button, Fade, FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { isEmpty, isString, round, toLower } from 'lodash-es';
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TransitionGroup } from 'react-transition-group';
import CardTypeOptions from '../../../dashboard/commerce/payment/cardType';

export default function StorePaymentMethod( {
	storeOrder,
	required,
	cardType,
	setCardType,
	cancelMethod,
	confirmMethod,
	store,
}: {
	storeOrder: boolean,
	required: boolean,
	cardType: string,
	store: Store,
	setCardType?: ( cardType: string ) => void,
	cancelMethod?: () => void,
	confirmMethod?: (
		paymentMethod?: string,
		paymentGateway?: GatewayBase,
		isCardFeeAllowed?: boolean,
		cardId?: string ) => Promise<void>
} ) {
	const { t } = useTranslation();
	const router = useRouter();
	const { enqueueSnackbar } = useSnackbar();
	const {
		      gateway: _gateway,
		      companyLocation,
		      company,
		      client,
		      type,
		      metadata,
		      paidTotal,
		      grandTotal,
		      externalId,
	      } = useGraphqlResult<Order>();
	
	const isCloverAccount = useAccountType( 'CLOVER', company );
	
	const [ paymentMethod, setPaymentMethod ] = useState( '' );
	const [ cardId, setCardId ] = useState( '' );
	const [ paymentGateway, setPaymentGateway ] = useState<GatewayBase>( null );
	const gateway = _gateway || companyLocation?.gateway || company.mainPayment;
	
	const syncedToClover = !isEstimateType( type ) && externalId?.length > 12 && externalId?.length < 16;
	
	const { data: gatewayData } = useGraphQL<QueryGatewaysReadArgs>( {
		query    : GatewaysRead,
		queryKey : [ 'gatewaysRead' ],
		variables: { options: { limit: 15, filter: { active: true, company: company.id } } },
	}, { keepPreviousData: true, enabled: true } );
	
	const { data: cards, refetch } = useQuery( [ 'cards' ], async () => {
		const { data } = await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/processor/payment/getCard`, {
			id: client?.id,
		} );
		if ( data?.cards?.elements ) {
			return data.cards.elements.filter( ( card ) => card?.token && card?.token?.startsWith( 'clv' ) );
		}
	}, { enabled: !client?.externalId } );
	
	const cloverGateway = gatewayData?.gatewaysRead?.items?.find( ( gateway ) => gateway.external === 'CLOVER' );
	
	const cardConnectAchGateway = gatewayData?.gatewaysRead?.items?.find( ( gateway ) => gateway.external === 'CARD_CONNECT' );
	
	const defaultAchGateway = company.mainPayment?.id === cardConnectAchGateway?.id || !gatewayData?.gatewaysRead?.items?.some( ( gateway ) => gateway.external === 'STRIPE' ) && cardConnectAchGateway?.id
		? cardConnectAchGateway
		: gatewayData?.gatewaysRead?.items?.find( ( gateway ) => gateway.external === 'STRIPE' );
	
	const cashDiscount = paidTotal === 0 && metadata?.enableCashDiscount && round( company.metadata?.cashDiscount || 0, 2 );
	const storeOrderWithGateway = gateway?.active || company.mainPayment?.active;
	const stripeAndClover = gateway?.external === 'STRIPE' && cloverGateway;
	const stripePaymentMethods = gateway?.external === 'STRIPE' ? gateway?.paymentMethod : [];
	const stripeHasSingleMethod = !cloverGateway && gateway?.external === 'STRIPE' && stripePaymentMethods?.length === 1;
	const isStripeNoCloverAndOnlyACH = stripeHasSingleMethod && stripePaymentMethods?.[ 0 ] === 'ACH';
	const stripeHasBothMethods = !cloverGateway && gateway?.external === 'STRIPE' && stripePaymentMethods?.includes( 'ACH' ) && stripePaymentMethods?.includes( 'CREDIT_CARD' );
	
	const cardFeeAllowed = !metadata?.enableCardFee && ( store.paymentOptions?.processing_fee ?? company.metadata?.cardFee > 0 );
	
	const showCardSelection = !cardFeeAllowed || isStripeNoCloverAndOnlyACH || company.metadata?.includeDebitCardFee
		? false
		: company.metadata?.cardFee && paymentMethod === 'card' && !metadata?.enableCardFee;
	
	return (
		<Fragment>
			<TransitionGroup>
				<RadioGroup
					sx={{
						'.MuiFormControlLabel-root': {
							'width'       : 'max-content',
							'pr'          : 1.5,
							'borderRadius': 2,
							'transition'  : '.2s all',
							':hover'      : {
								bgcolor: 'divider',
							},
						},
					}}
					value={paymentMethod}
					onChange={( e ) => {
						setCardType?.( '' );
						if ( e.target.value && e.target.value.includes( 'saved' ) ) {
							const cardToken = e.target.value.split( '-' )?.[ 1 ];
							if ( cardToken ) {
								setCardId( cardToken );
							}
						}
						setPaymentMethod( e.target.value );
						if ( e.target.value === 'ach' ) {
							const stripeGateway = gateway?.external === 'STRIPE' && gateway;
							if ( !defaultAchGateway && !stripeGateway ) {
								enqueueSnackbar( `If you need bank transfer, please contact Invoiss support. ${required
									? 'Contact the merchant.'
									: ''}`, { variant: 'info' } );
								return;
							}
							setPaymentGateway( defaultAchGateway || stripeGateway );
						} else if ( e.target.value === 'card' && company.mainPayment?.external === 'CARD_CONNECT' && ( !isCloverAccount || !cloverGateway ) ) {
							setPaymentGateway( company.mainPayment );
						} else if ( e.target.value === 'card' && cloverGateway ) {
							setPaymentGateway( gateway?.external === 'CLOVER' ? gateway : cloverGateway );
						} else {
							// if Card Connect is the only and the main payment, skip gateway
							gateway?.external === 'CARD_CONNECT' ? setPaymentGateway( null ) : setPaymentGateway( gateway );
						}
					}}>
					{!store.paymentOptions?.card
						? null
						: client && !metadata?.disablePayment && !isEmpty( cards ) && cards.map( ( card, index ) => (
							<Fade key={index} in timeout={900}>
								<FormControlLabel
								key={card.id}
								value={`saved-${card.token}`}
								control={<Radio/>}
								disabled={!syncedToClover && !gateway?.external === 'STRIPE' && !storeOrder}
								label={(
									<Fragment>
										Saved Card {card.last4 ? `(**** ${card.last4}) ${card?.expirationDate
										? `Exp: ${card.expirationDate?.substring( 0, 2 )}/${card.expirationDate.substring( 2 )}`
										: ''}` : ''}
										{!cardFeeAllowed
											? ''
											: company.metadata?.cardFee && !metadata?.enableCardFee && !metadata?.cardFee
												? ` (${round( company.metadata?.cardFee, 2 )}% Charge)`
												: ''}
										<AsyncLoadingButton
											color='error'
											onClick={async () => {
												const { data } = await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/processor/payment/deleteCard`, {
													id      : client.id,
													cardId  : card.id,
													sourceId: card.token,
												} );
												enqueueSnackbar( isString( data )
													? data
													: t( 'common:card-remove-success' ), { variant: 'success' } );
												await refetch();
											}}>
											Remove
										</AsyncLoadingButton>
									</Fragment>
								)}
								onClick={() => setCardId( card?.token )}
								/>
							</Fade>
					) )}
					{!store.paymentOptions?.card
					|| ( isStripeNoCloverAndOnlyACH || metadata?.disablePayment || !storeOrderWithGateway ) && stripeAndClover
						? null
						: gateway?.active || company.mainPayment?.active ? (
							<Fade in timeout={900}>
								<Box>
									<FormControlLabel
										value='card'
										control={<Radio/>}
										disabled={!company.mainPayment?.external === 'CARD_CONNECT' && !syncedToClover && !gateway?.external === 'STRIPE' && !storeOrder}
										label={`${t( 'common:card' )} ${isCloverAccount && !syncedToClover && !gateway?.external === 'STRIPE' && !storeOrder
											? `- ${t( 'common:please-sync' )} ${toLower( type )} ${t( 'common:please-sync-post' )}`
											: ''}`}
									/>
									{showCardSelection ? (
										<CardTypeOptions
											cardType={cardType}
											setCardType={setCardType}
											cardFeePercent={company.metadata?.cardFee}
										/>
									) : null}
								</Box>
							</Fade>
						) : null}
					{!eligibleForBankTransfer( 'bank_transfer', store.paymentOptions, gatewayData?.gatewaysRead?.items )
						? null
						: ( isStripeNoCloverAndOnlyACH || stripeHasBothMethods || defaultAchGateway ) && !defaultAchGateway?.achDisabled && !metadata?.disableBankTransfer && (
							<Fade in timeout={900}>
								<FormControlLabel
								value='ach'
								disabled={grandTotal < 2}
								control={<Radio/>}
								label={`${grandTotal < 2
									? t( 'common:bank-transfer-pre' )
									: t( 'common:bank-transfer' )}`}
								/>
							</Fade>
					)}
				</RadioGroup>
			</TransitionGroup>
			{required && router.route.split( '/' )[ 1 ] === 'p' && cashDiscount > 0 && (
				<Typography color='warning.main' component='span'>
					{t( 'commerce:cash-or-check-discount', { cashDiscount: cashDiscount } )}
				</Typography>
			)}
			<Stack spacing={1} direction='row' alignItems='center' mt={2}>
				{cancelMethod && (
					<Button
						sx={{ mr: 1 }}
						variant='outlined'
						startIcon={<ArrowBackIosIcon/>}
						onClick={cancelMethod}>
						{t( 'common:back' )}
					</Button>
				)}
				<AsyncLoadingButton
					disabled={!paymentMethod || paymentMethod === 'offlineACH' || paymentMethod === 'Invoice Credit' && showCardSelection && !cardType}
					variant='contained'
					color='primary'
					sx={{ width: 150 }}
					onClick={async () => { // TODO: this can set global atoms here
						await confirmMethod( paymentMethod, paymentGateway, cardFeeAllowed, cardId );
					}}>
					Continue
				</AsyncLoadingButton>
			</Stack>
		</Fragment>
	);
}

