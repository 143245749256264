import Intercom from '@/baseline/intercom';
import PageProgress from '@/components/page/progress';
import { CompleteSplash } from '@/providers/splash';
import type { EmotionCache } from '@emotion/cache';
import { LicenseInfo } from '@mui/x-license-pro';
import type { ReactNode } from 'react';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import Layout from '../layout';
import AppStyles from './appStyles';
import InitialSnack from './initialSnack';
import FeedbackSnack from './navigation/feedbackSnack';
import Offline from './offline';
import Providers from './providers';

LicenseInfo.setLicenseKey( '7a3efa242ae286ffa8861b3c4d4247caTz04NDIxMSxFPTE3Mzk0OTMxMDkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=' );

export default function Baseline( {
	pageProps,
	cache,
	children,
}: {
	pageProps,
	cache: EmotionCache,
	children?: ReactNode
} ) {
	return (
		<Providers pageProps={pageProps} cache={cache}>
			<PageProgress options={{ showSpinner: false }}/>
			<CompleteSplash/>
			<Offline/>
			<InitialSnack/>
			<FeedbackSnack/>
			{/*<OneTimeModalPopUp*/}
			{/*	title='Tag invoices, orders and their line items'*/}
			{/*	description='You can now tag invoices, orders and their line items with your own custom status.'*/}
			{/*	hideModalKey='hideSignAndTagModal'*/}
			{/*/>*/}
			{/*<ChatActivationModal/>*/}
			<Intercom/>
			<AppStyles/>
			<Layout>{children}</Layout>
		</Providers>
	);
}
