import PageLink from '@/components/page/link';
import PageLinkComponent from '@/components/page/linkComponent';
import StyledImage from '@/components/styledImage';
import useShowSnackBarAfterReload from '@/hooks/useShowSnackBarAfterReload';
import { useCompanyId } from '@/providers/auth/useSessionStorageCompanyId';
import useUserInfo from '@/providers/auth/useUserInfo';
import { CompleteSplash } from '@/providers/splash';
import {
	AddRounded as AddRoundedIcon,
	CheckCircle as CheckCircleIcon,
	Lock as LockIcon,
	Storefront as StorefrontIcon,
} from '@mui/icons-material';
import {
	Avatar,
	Button,
	Container,
	ListItemAvatar,
	ListItemText,
	MenuItem,
	Paper,
	Stack,
	Typography,
} from '@mui/material';

export default function ConnectViaWeb() {
	const { user, staff } = useUserInfo();
	const { setCompanyId } = useCompanyId();
	const showSnackBarAfterReload = useShowSnackBarAfterReload();
	
	return (
		<Container maxWidth='md' sx={{ mt: 5 }}>
			<CompleteSplash/>
			<Paper variant='borderless' sx={{ p: 3 }}>
				<Stack direction='row' alignItems='center' spacing={2} mb={2}>
					<StyledImage
						alt='square-logo'
						src='/images/clover-icon.png'
						sx={{ width: 40, height: 40 }}
					/>
					<AddRoundedIcon sx={{ fontSize: 40 }}/>
					<StyledImage
						alt='square-logo'
						src='/images/invoiss-logo-dark.png'
						sx={{ width: 40, height: 40 }}
					/>
				</Stack>
				<Stack spacing={3} sx={{ '.MuiTypography-body1': { fontSize: 14 } }}>
					<Typography variant='h1' mt={1}>
						Welcome To Invoiss
					</Typography>
					<Typography variant='h4'>
						Great! You're on the Invoiss app, but your Clover connection is incomplete!
					</Typography>
					<ul>
						<li>
							<Typography component='span'>
								Sign in to{' '}
								<PageLink
									underline='hover'
									sx={{ fontWeight: 'bold' }}
									href='https://clover.com'>
									Clover.com
								</PageLink>
								{' '} on a desktop computer and click on the Invoiss app on the left panel.
								<br/>
							</Typography>
						</li>
						<li>
							It would sync to your Invoiss account. This process only takes a few minutes but it has to be done
							via Clover dashboard.
						</li>
					</ul>
					<Button
						sx={{ mt: 2, alignSelf: 'start' }}
						variant='contained'
						startIcon={<LockIcon/>}
						color='error'
						component={PageLinkComponent}
						href='/logout'>
						Log Out
					</Button>
					<Typography sx={{ my: 2 }}>
						Here are the companies you are a part of right now:
					</Typography>
					{user?.staffs.length > 1 && (
						<Stack spacing={1}>
							{user?.staffs.map( ( newStaff, index ) => (
								<MenuItem
									key={index}
									selected={staff.id === newStaff.id}
									sx={{ mb: .5, p: 1 }}
									onClick={() => {
										setCompanyId( newStaff.company.id );
										window.open( '/dashboard', '_self' );
										showSnackBarAfterReload( `You are now in ${newStaff.company.name}`, { variant: 'default' } );
									}}>
									<ListItemAvatar sx={{ minWidth: 45 }}>
										<Avatar
											src={newStaff?.company?.logo}
											sx={{
												width  : 30,
												height : 30,
												bgcolor: newStaff?.company?.logo && '#ffffff',
												img    : { objectFit: 'contain' },
											}}>
											<StorefrontIcon/>
										</Avatar>
									</ListItemAvatar>
									<ListItemText
										primaryTypographyProps={{ variant: 'h5' }}
										primary={newStaff?.company?.name}
									/>
									{staff.id === newStaff.id && <CheckCircleIcon sx={{ ml: 5 }}/>}
								</MenuItem>
							) )}
						</Stack>
					)}
				</Stack>
			</Paper>
		</Container>
	);
}
