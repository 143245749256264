import ConditionalSkeleton from '@/components/animations/conditionalSkeleton';
import ChatSideBarSkeleton from '@/components/skeletons/chatSideBarSkeleton';
import { Room, useRooms } from '@/firebase/firestore';
import { useCompanyId } from '@/providers/auth/useSessionStorageCompanyId';
import { RoomType } from '@/types/chatRoom';
import { companyMapAtom } from '@/utils/atoms';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { Avatar, Box, Button, Divider, Fade, ListItemButton, ListItemText, Stack } from '@mui/material';
import { useAtom } from 'jotai/index';
import { useRouter } from 'next/router';
import React, { Fragment, useEffect, useState } from 'react';
import RoomItem from '../../dashboard/chat/roomItem';

export default function ClientRoomSideBar() {
	const { sessionCompanyId } = useCompanyId();
	const { rooms, loading } = useRooms( sessionCompanyId, RoomType.EXTERNAL, true );
	const router = useRouter();
	const [ companyAtom ] = useAtom( companyMapAtom );
	const [ selectedCompanyId, setSelectedCompanyId ] = useState( null );
	const roomsByCompany = rooms?.reduce( ( acc: Record<string, Room[]>, room: Room ) => {
		( acc[ room.company ] = acc[ room.company ] || [] ).push( room );
		return acc;
	}, {} );
	const [ isInitial, setIsInitial ] = useState( true );
	const handleBackClick = () => {
		setSelectedCompanyId( null );
	};
	
	useEffect( () => {
		if ( router.query.id && isInitial ) {
			setIsInitial( false );
			const room = rooms?.find( ( room ) => room.id === router.query.id );
			if ( room ) {
				setSelectedCompanyId( room.company );
			}
		}
	}, [ rooms ] );
	
	return (
		<Stack width='100%' sx={{ overflow: 'overlay' }}>
			<ConditionalSkeleton skeleton={<ChatSideBarSkeleton/>} isLoading={loading}>
				{!selectedCompanyId
					? Object.entries( roomsByCompany || {} ).map( ( [ company, rooms ] ) => {
						const roomCompany = companyAtom[ company ];
						return (
							<Fade key={company} in timeout={1000}>
								<Box>
									<ListItemButton
										sx={{ m: 1 }}
										onClick={() => setSelectedCompanyId( company )}>
										<Avatar
											sx={{ borderRadius: 2, width: 30, height: 30, fontSize: 12 }}
											variant='rounded'>
											{roomCompany?.name?.[ 0 ]}
										</Avatar>
										<ListItemText
											primary={roomCompany?.name}
											secondary={`${rooms?.length} chats`}
											primaryTypographyProps={{ noWrap: true, sx: { color: 'text.secondary' } }}
											secondaryTypographyProps={{ noWrap: true }}
											sx={{ ml: 1 }}
										/>
									</ListItemButton>
									<Divider/>
								</Box>
							</Fade>
						);
					} ) : (
						<Fragment>
							<Button
								startIcon={<ArrowBackIcon/>}
								variant='text'
								color='primary'
								sx={{
									mt           : 1,
									ml           : 1,
									textTransform: 'none',
									boxShadow    : 'none !important',
									fontSize     : 16,
								}}
								onClick={handleBackClick}>
								Back
							</Button>
							{roomsByCompany[ selectedCompanyId ].map( ( room: Room ) => (
								<RoomItem key={room.id} clientView room={room}/>
							) )}
						</Fragment>
					)}
			</ConditionalSkeleton>
		</Stack>
	);
}
