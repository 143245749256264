import PageLinkComponent from '@/components/page/linkComponent';
import { Settings as SettingsIcon } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { useRouter } from 'next/router';

export default function SettingsButton( { title, href }: { title: string, href: string } ) {
	const router = useRouter();
	const isSettingsRoute = router.asPath.split( '/' )[ 2 ] === 'settings';
	return (
		<Tooltip placement='bottom' title={title}>
			<IconButton component={PageLinkComponent} href={href} color={isSettingsRoute ? 'primary' : 'default'}>
				<SettingsIcon/>
			</IconButton>
		</Tooltip>
	);
}
