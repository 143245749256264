import { Skeleton, Stack } from '@mui/material';

export default function ChatSideBarSkeleton( { length = 5 }: { length?: number } ): JSX.Element {
	return (
		<Stack spacing={2} width='100%' p={1}>
			{[ ...new Array( length ) ].map( ( _, index ) => (
				<Skeleton
					key={index}
					animation='wave'
					width='100%'
					height='100px'
					sx={{ transform: 'unset' }}
				/>
			) )}
		</Stack>
	);
}

