import Form from '@/components/form';
import FormattedTextField from '@/components/formattedTextField';
import Loading from '@/components/loading';
import SearchBar from '@/components/searchBar';
import { useInfiniteGraphQL } from '@/data';
import { ItemsRead } from '@/data/management/item.graphql';
import currencyFormat from '@/helpers/currencyFormat';
import { createLineItemThroughItem } from '@/pages/dashboard/commerce/form/lineItem/lineItemUtils';
import CategorySelect from '@/pages/formSelects/categorySelect';
import VendorSelect from '@/pages/formSelects/vendorSelect';
import { Category, Gateway, Item, LineItem, Menu, QueryItemsReadArgs, type Uom } from '@/types/schema';
import { Paper, Stack, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import { set, throttle } from 'lodash-es';
import React, { ChangeEvent, Fragment, useEffect, useState } from 'react';
import { TableVirtuoso } from 'react-virtuoso';

export default function UpdateReceiving( {
	gateway,
	lineItems,
	setLineItems,
}: {
	gateway: Gateway,
	lineItems: LineItem[],
	setLineItems: ( lineItems: LineItem[] ) => void
} ) {
	const [ search, setSearch ] = useState( '' );
	const [ newQuantities, setNewQuantities ] = useState( {} );
	const [ category, setCategory ] = useState<Category>( null );
	const [ vendor, setVendor ] = useState<Menu>( null );
	const [ purchaseLineItems, setPurchaseLineItems ] = useState<LineItem[]>( lineItems || [] );
	
	const {
		      flattenedData,
		      isLoading,
		      isFetching,
		      hasNextPage,
		      fetchNextPage,
		      count,
	      } = useInfiniteGraphQL<QueryItemsReadArgs>( {
		query    : ItemsRead,
		queryKey : [ 'item' ],
		variables: {
			options: {
				limit  : 50,
				filter : {
					gateway : gateway?.id,
					isHidden: null,
					uoms    : { selected: true },
					...search?.length && {
						$or: [
							'name',
							'uoms.name',
							'uoms.sku',
						].map( ( field ) => set( {}, field, { $ilike: search } ) ),
					},
				},
				orderBy: [ 'name:ASC' ],
			},
		},
	}, { keepPreviousData: true } );
	
	useEffect( () => {
		setLineItems( purchaseLineItems );
	}, [ purchaseLineItems ] );
	
	useEffect( () => {
		purchaseLineItems.forEach( ( lineItem ) => {
			if ( lineItem.uom?.id && !newQuantities?.[ lineItem.uom?.id ] && lineItem.uom?.quantity ) {
				setNewQuantities( ( prev ) => ( {
					...prev,
					[ lineItem.uom.id ]: lineItem.uom?.quantity ?? '',
				} ) );
			}
		} );
	}, [ flattenedData ] );
	
	const handleChange = throttle( async (
		item: Item,
		selectedUom: Uom,
		e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	) => {
		if ( !selectedUom ) return;
		
		const newQuantity = e.target.value.trim() === '' ? '' : +e.target.value;
		
		setNewQuantities( ( prev ) => ( {
			...prev,
			[ selectedUom?.id ]: newQuantity,
		} ) );
		
		// if new quantity is empty, remove the lineItem
		if ( newQuantity === '' ) {
			const newLineItems = purchaseLineItems.filter( ( lineItem ) => lineItem.uom.id !== selectedUom.id );
			setPurchaseLineItems( newLineItems );
			return;
		}
		
		// if the lineItem is not in the lineItems array, add it
		if ( !purchaseLineItems.find( ( lineItem ) => lineItem?.uom?.id === selectedUom?.id ) ) {
			const lineItem = await createLineItemThroughItem( item, {
				...selectedUom,
				quantity: selectedUom?.quantity || 0,
			}, undefined, undefined, undefined, undefined, +newQuantity );
			
			setPurchaseLineItems( [ ...purchaseLineItems, lineItem as LineItem ] );
			
		} else {
			// update the uom quantity
			const newLineItems = [ ...purchaseLineItems ];
			const lineItemIndex = newLineItems.findIndex( ( lineItem ) => lineItem?.uom?.id === selectedUom?.id );
			newLineItems[ lineItemIndex ].receivedQuantity = +newQuantity;
			newLineItems[ lineItemIndex ].uom.quantity = ( newLineItems[ lineItemIndex ].uom.quantity || 0 ) + +newQuantity;
			setPurchaseLineItems( newLineItems );
		}
	}, 300 );
	
	if ( !flattenedData && ( isLoading || isFetching ) || Boolean( search ) && isLoading ) return <Loading/>;
	
	return (
		<Paper sx={{ bgcolor: 'background.default', p: 1, height: 600 }}>
			<Form initialValues={{ category, vendor }} onSubmit={null}>
				<Stack
					direction='row'
					alignItems='end'
					spacing={2}
					my={1}
					width={{ xs: '100%' }}>
					<SearchBar
						fullWidth
						size='small'
						placeholder='Search to see more items'
						setSearch={setSearch}
						searchText={search}
						label='Search'
					/>
					<CategorySelect
						fullWidth
						name='category'
						type='ITEM'
						onAdd={undefined}
						onChange={async ( e, newValue: Menu ) => setCategory( newValue )}
					/>
					<VendorSelect
						fullWidth
						name='vendor'
						variables={{ options: { limit: 50, filter: { active: true } } }}
						onAdd={undefined}
						onChange={async ( e, newValue: Menu ) => setVendor( newValue )}
					/>
				</Stack>
			</Form>
			<TableContainer
				sx={{
					'borderRadius'      : 2,
					'borderColor'       : 'divider',
					'overflow'          : 'hidden',
					'minHeight'         : 500,
					'height'            : 500,
					'.MuiTableCell-root': { padding: 1 },
				}}>
				<TableVirtuoso
					totalCount={flattenedData.length}
					data={flattenedData}
					itemContent={( index, item ) => {
						const selectedUom = item?.uoms?.find( ( uom ) => uom.selected );
						return (
							<Fragment key={index}>
								<TableCell sx={{ width: 300, minWidth: 300 }}>
									<Typography>
										{`${item.name} (${selectedUom?.name})`}
									</Typography>
								</TableCell>
								<TableCell width={200}>
									<Typography>{selectedUom?.name || '-'}</Typography>
								</TableCell>
								<TableCell width={200}>
									<Typography>{selectedUom?.code || '-'}</Typography>
								</TableCell>
								<TableCell width={200}>
									<Typography>{selectedUom?.sku || '-'}</Typography>
								</TableCell>
								<TableCell width={200}>
									<Typography>{currencyFormat( selectedUom?.cost || undefined )}</Typography>
								</TableCell>
								<TableCell width={200}>
									<Typography>{selectedUom?.quantity || '-'}</Typography>
								</TableCell>
								<TableCell align='right'>
									<FormattedTextField
										fullWidth
										sx={{ width: 150 }}
										type='number'
										value={newQuantities[ selectedUom?.id ] ?? ''}
										onChange={( e ) => handleChange( item, selectedUom, e )}
										onFocus={( e ) => e.target.select()}
									/>
								</TableCell>
							</Fragment>
						);
					}}
					endReached={async () => {
						if ( hasNextPage && !isFetching ) await fetchNextPage();
					}}
					fixedHeaderContent={() => (
						<TableRow
							sx={{
								bgcolor: ( theme ) => theme.palette.mode === 'dark'
									? '#37383A !important'
									: '#E0E0E0 !important',
							}}>
							<TableCell>
								<Typography>Name</Typography>
							</TableCell>
							<TableCell>
								<Typography>Unit</Typography>
							</TableCell>
							<TableCell>
								<Typography>Code</Typography>
							</TableCell>
							<TableCell>
								<Typography>SKU</Typography>
							</TableCell>
							<TableCell>
								<Typography>Cost</Typography>
							</TableCell>
							<TableCell>
								<Typography>Quantity on Hand</Typography>
							</TableCell>
							<TableCell align='right'>
								<Typography>Add to Stock</Typography>
							</TableCell>
						</TableRow>
					)}
				/>
			</TableContainer>
			{isFetching ? <Loading/> : !hasNextPage && count > 10 ? (
				<Typography sx={{ textAlign: 'center', color: 'text.secondary', mt: 2 }}>
					No more results
				</Typography>
			) : null}
		</Paper>
	);
}
