import currencyFormat from '@/helpers/currencyFormat';
import { calculations } from '@/pages/dashboard/commerce/components/calculations';
import { CARD_FEE_LABEL } from '@/pages/p/commerce/invoiceEstimate/lineItems';
import { Order } from '@/types/schema';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, ListItem, ListItemText, Typography } from '@mui/material';
import { partition } from 'lodash';
import { isEmpty, sortBy } from 'lodash-es';
import React, { Fragment, useState } from 'react';

export default function SubTotalSection( {
	order,
	drawer,
	surchargePercent,
	cardFeeAmount,
	finalPaying,
}: {
	order: Order,
	surchargePercent?: number,
	drawer?: boolean,
	cardFeeAmount?: number,
	finalPaying?: number
} ) {
	const [ expanded, setExpanded ] = useState<boolean>( false );
	const { nonTaxPercentLineItemsTaxesData, orderTaxCal, orderDiscounts, multiTaxesData } = calculations( order );
	const otherPrices = order.prices?.filter( ( { name } ) => name !== CARD_FEE_LABEL );
	
	const sortedDiscountsAndFees = [ ...sortBy( !isEmpty( otherPrices )
		? [ ...otherPrices ]
		: [], [ ( o ) => o.value ] ) ];
	
	const [ discounts, fees ] = partition( sortedDiscountsAndFees
		.filter( ( price ) => !price.metadata?.cloverTaxPercent ), ( { value } ) => value < 0 );
	
	const paying = finalPaying || order.grandTotal || 0;
	const finalPayingWithSurcharge = paying + paying * ( surchargePercent || 0 ) / 100;
	
	const subTotal = order.subTotal || 0;
	
	const content = (
		<Fragment>
			{surchargePercent && surchargePercent > 0 ? (
				<ListItem disablePadding sx={{ color: 'text.secondary' }}>
					<ListItemText
						primary={`There is a surcharge of ${surchargePercent}%`}
						primaryTypographyProps={{ sx: { fontSize: '15px !important' } }}
					/>
					<Typography sx={{ fontSize: '15px !important' }}>
						{currencyFormat( paying * surchargePercent / 100 )}
					</Typography>
				</ListItem>
			) : null}
			<ListItem disablePadding sx={{ color: 'text.secondary' }}>
				<ListItemText
					primary='Sub Total'
					primaryTypographyProps={{ sx: { fontSize: '15px !important' } }}
				/>
				<Typography sx={{ fontSize: '15px !important' }}>
					{currencyFormat( subTotal )}
				</Typography>
			</ListItem>
			{subTotal > 0 && !isEmpty( multiTaxesData ) && multiTaxesData.map( ( taxData, index ) => (
				<ListItem key={index} disablePadding sx={{ color: 'text.secondary' }}>
					<ListItemText
						primary={`${taxData?.[ 0 ]} ${taxData?.[ 3 ] ? ` (${taxData?.[ 1 ]}%)` : ''}`}
						primaryTypographyProps={{ sx: { fontSize: '15px !important' } }}
					/>
					<Typography sx={{ fontSize: '15px !important' }}>
						{currencyFormat( taxData?.[ 2 ] ?? 0 )}
					</Typography>
				</ListItem>
			) ) || []}
			{!isEmpty( nonTaxPercentLineItemsTaxesData ) ? nonTaxPercentLineItemsTaxesData.map( ( taxData,
				index ) => (
					<ListItem key={index} disablePadding sx={{ color: 'text.secondary' }}>
						<ListItemText
						primary={`Tax ${` (${taxData?.[ 0 ]}%)`}`}
						primaryTypographyProps={{ sx: { fontSize: '15px !important' } }}
						/>
						<Typography sx={{ fontSize: '15px !important' }}>
							{currencyFormat( taxData?.[ 1 ] )}
						</Typography>
					</ListItem>
			) ) : undefined}
			{orderTaxCal <= 0 || order.taxPercent <= 0 || order.taxTotal <= 0 && isEmpty( orderDiscounts )
				? undefined
				: (
					<ListItem disablePadding sx={{ color: 'text.secondary' }}>
						<ListItemText
							primary={`Tax ${` (${order.taxPercent}%)`}`}
							primaryTypographyProps={{ sx: { fontSize: '15px !important' } }}
						/>
						<Typography sx={{ fontSize: '15px !important' }}>
							{currencyFormat( orderTaxCal )}
						</Typography>
					</ListItem>
				)}
			
			{!isEmpty( discounts ) && subTotal && discounts.map( ( fee, index ) => (
				<ListItem key={index} disablePadding sx={{ color: 'text.secondary' }}>
					<ListItemText
							primary={`${fee.name} ${fee.isPercent ? ` (${fee.value}%)` : ''}`}
							primaryTypographyProps={{ sx: { fontSize: '15px !important' } }}
					/>
					<Typography sx={{ fontSize: '15px !important' }}>
						{fee.isPercent
								? currencyFormat( subTotal * fee.value / 100 )
								: currencyFormat( fee.value )}
					</Typography>
				</ListItem>
				),
			)}
			{!isEmpty( fees ) && subTotal && fees.map( ( fee, index ) => (
				<ListItem key={index} disablePadding sx={{ color: 'text.secondary' }}>
					<ListItemText
							primary={`${fee.name} ${fee.isPercent ? ` (${fee.value}%)` : ''}`}
							primaryTypographyProps={{ sx: { fontSize: '15px !important' } }}
					/>
					<Typography sx={{ fontSize: '15px !important' }}>
						{fee.isPercent
								? currencyFormat( subTotal * fee.value / 100 )
								: currencyFormat( fee.value )}
					</Typography>
				</ListItem>
				),
			)}
			{cardFeeAmount ? cardFeeAmount > 0 && (
				<ListItem disablePadding sx={{ color: 'text.secondary' }}>
					<ListItemText primary='Card Fee'/>
					<Typography>{currencyFormat( cardFeeAmount )}</Typography>
				</ListItem>
			) : null}
			<ListItem disablePadding>
				<ListItemText primary='Total' primaryTypographyProps={{ variant: 'h3' }}/>
				<Typography variant='h3'>{currencyFormat( finalPayingWithSurcharge )}</Typography>
			</ListItem>
		</Fragment>
	);
	
	if ( drawer ) {
		return (
			<Accordion
				square
				elevation={0}
				defaultExpanded={false}
				sx={{
					'mb'          : 2,
					'bgcolor'     : 'background.default',
					'borderRadius': 2,
					'&:before'    : {
						display: 'none',
					},
				}}
				onChange={() => setExpanded( !expanded )}>
				<AccordionSummary expandIcon={<ExpandMoreIcon/>}>
					<Typography variant='h5'>
						{expanded ? 'Order Summary' : `Total: ${currencyFormat( finalPayingWithSurcharge )}`}
					</Typography>
				</AccordionSummary>
				<AccordionDetails>
					{content}
				</AccordionDetails>
			</Accordion>
		);
	}
	
	return content;
}
