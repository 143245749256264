import CallEffect from '@/components/callEffect';
import PageWrapper from '@/components/page/wrapper';
import { axiosClient } from '@/data';
import { OrderPublicRead } from '@/data/commerce/order.graphql';
import PageGraphqlProvider from '@/data/query/pageGraphqlProvider';
import { isInvoiceType } from '@/helpers/useIsEstimateType';
import SubTotalSection from '@/pages/p/store/cart/subTotal';
import { useGetStoreAtom, useStorePublicRead, useUpdateOnlineStoreAtom } from '@/pages/p/store/context';
import { fetchCloverSurcharge } from '@/pages/settings/cards';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useModalControls } from '@/providers/modal';
import { GatewayBase, Order } from '@/types/schema';
import { Close as CloseIcon } from '@mui/icons-material';
import { IconButton, Stack, Step, StepContent, StepLabel, Stepper, Typography } from '@mui/material';
import axios from 'axios';
import { round, startCase, toLower } from 'lodash-es';
import { useRouter } from 'next/router';
import { useState } from 'react';
import PaymentDetails from '../../../dashboard/commerce/payment/details';
import ClientInfoForm from './clientInfoForm';
import StorePaymentMethod from './method';
import { printAndSendOrder } from './utils';

export default function StorePaymentDrawer() {
	const router = useRouter();
	const { staff } = useUserInfo();
	const { closeModal } = useModalControls();
	const { store } = useStorePublicRead();
	const setOnlineStoreAtom = useUpdateOnlineStoreAtom();
	
	const [ cardType, setCardType ] = useState( '' );
	const [ step, setStep ] = useState( 0 );
	const [ method, setMethod ] = useState<string | undefined>( undefined );
	const [ paymentGateway, setPaymentGateway ] = useState<GatewayBase | null>( null );
	const [ cardFeeAllowed, setCardFeeAllowed ] = useState( null );
	const [ surchargePercent, setSurchargePercent ] = useState( 0 );
	
	const { atomOrderId } = useGetStoreAtom();
	
	const savedCardMethod = toLower( method ).includes( 'saved' );
	
	if ( !atomOrderId ) return null;
	
	return (
		<PageGraphqlProvider<Order>
			queryKey='orderPublicRead'
			query={OrderPublicRead}
			variables={{ id: atomOrderId }}
			subscription={{ ORDER: atomOrderId }}>
			{( order ) => {
				const cardFee = surchargePercent
					? 0
					: cardFeeAllowed && cardType !== 'debit' && ( method === 'card' || savedCardMethod )
					&& order.company.metadata?.cardFee > 0
					&& !order.metadata?.enableCardFee
					&& order.company.metadata?.cardFee / 100;
				
				const amountToPay = ( order.grandTotal || 0 ) - ( order.paidTotal || 0 );
				
				const payingBeforeTax = amountToPay - ( order.taxTotal || 0 ) * amountToPay / ( ( order.subTotal || 0 ) + ( order.taxTotal || 0 ) );
				const cardFeeAmount = payingBeforeTax * cardFee;
				const finalPaying = round( amountToPay + cardFeeAmount, 2 );
				const gateway = order?.gateway || order?.companyLocation?.gateway;
				
				const cloverGateway = gateway?.external === 'CLOVER' && gateway;
				
				return (
					<PageWrapper
						hideBack
						modalWrapper
						elevationScrollProps={{ sx: { ':after': { display: 'hidden' }, 'borderBottom': 1 } }}
						primary={(
							<Stack spacing={1}>
								<CallEffect
									func={async () => {
										if ( ( method === 'card' || method?.includes( 'saved' ) ) && cloverGateway?.id ) {
											await fetchCloverSurcharge( cloverGateway.id, setSurchargePercent );
										}
									}}
									deps={[ cloverGateway, method ]}
								/>
								<Stack mb={1} direction='row' alignItems='center' justifyContent='space-between'>
									<Typography variant='h4'>
										Checkout
									</Typography>
									<IconButton
										size='small'
										onClick={closeModal}>
										<CloseIcon/>
									</IconButton>
								</Stack>
								<SubTotalSection
									drawer
									order={order}
									surchargePercent={surchargePercent}
									cardFeeAmount={cardFeeAmount}
									finalPaying={finalPaying}
								/>
							</Stack>
						)}
						maxWidth={false}
						headerMaxWidth={false}
						boxProps={{ sx: { width: { md: 600 } } }}>
						<Stepper orientation='vertical' activeStep={step}>
							<Step key={0}>
								<StepLabel>
									<Typography variant='h5'>
										Your Information
									</Typography>
								</StepLabel>
								<StepContent TransitionProps={{ unmountOnExit: false }}>
									<ClientInfoForm goToNextStep={() => setStep( 1 )}/>
								</StepContent>
							</Step>
							<Step key={1}>
								<StepLabel>
									<Typography variant='h5'>
										{step <= 1 ? 'Select Payment Method' : `Method: ${savedCardMethod
											? 'Saved Card'
											: startCase( method )}`}
									</Typography>
								</StepLabel>
								<StepContent>
									<StorePaymentMethod
										storeOrder
										required
										store={store}
										cardType={cardType}
										setCardType={setCardType}
										cancelMethod={() => setStep( 0 )}
										confirmMethod={async ( method, gateway, isCardFeeAllowed ) => {
											setPaymentGateway( gateway );
											setMethod( method );
											setCardFeeAllowed( isCardFeeAllowed );
											setStep( 2 );
										}}
									/>
								</StepContent>
							</Step>
							<Step key={2}>
								<StepLabel>
									<Typography variant='h5'>Confirm</Typography>
								</StepLabel>
								<StepContent>
									<PaymentDetails
										hideSignature
										storeOrder
										cardFee={cardFeeAmount}
										cardFeeAllowedForStore={cardFeeAllowed}
										cardType={cardType}
										paymentGateway={paymentGateway}
										amount={finalPaying}
										method={method}
										cancel={async () => setStep( 1 )}
										confirm={async ( { method } ) => {
											if ( method !== 'ach' ) {
												setTimeout( async () => {
													await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/processor/manage/importOrder`, {
														id          : order.id,
														keepSameDate: true,
														staffId     : staff?.id,
													} ).catch( () => [] );
												}, 4000 );
											}
											await printAndSendOrder( order, store );
											
											setOnlineStoreAtom( { atomOrderId: null } );
											closeModal();
											await router.push( `/p/${order.id}/${isInvoiceType( order.type )
												? 'invoice'
												: toLower( order.type )}` );
										}}
									/>
								</StepContent>
							</Step>
						</Stepper>
					</PageWrapper>
				);
			}}
		</PageGraphqlProvider>
	);
}
