import GlobalBannerWrapper from '@/baseline/navigation/desktop/banners/wrapper';
import SubscriptionModal from '@/baseline/subscription';
import { getSubscription } from '@/baseline/subscription/getSubscription';
import { useCompany } from '@/hooks/useSetCompanyInAtom';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useModal } from '@/providers/modal';
import { dismissibleBannerAtoms } from '@/utils/atoms';
import { Button, Chip, Stack, Typography } from '@mui/material';
import { differenceInCalendarDays } from 'date-fns';
import { useAtom } from 'jotai/index';

export default function SubscriptionBanner() {
	const [ globalBanners, setGlobalBanners ] = useAtom( dismissibleBannerAtoms );
	const { company } = useCompany();
	const { showModal } = useModal();
	const subscription = getSubscription( { subscriptions: company?.subscriptions } );
	const companyIDs = [ '61b5a9d9-8e35-4fbf-9483-cdf4f799bbd8',
	                     'b7c8013e-a802-437c-aa0e-0edfa330bc8e',
	                     '480c5e25-a111-4d64-912f-9bd064567972' ];
	const { staff } = useUserInfo();
	
	return (
		<GlobalBannerWrapper
			color='alpha.amethyst'
			show={!globalBanners.hideSubscriptionBanner
				&& subscription
				&& subscription?.tier.name !== 'Trial'
				&& subscription?.tier.name !== 'Enterprise'
				&& subscription?.tier.name !== 'Premium'
				&& new Date( subscription?.endDate ) > new Date() || companyIDs.includes( staff?.company?.id || '' ) && subscription?.tier.name === 'Trial'}
			onClose={() => setGlobalBanners( ( prev ) => ( { ...prev, hideSubscriptionBanner: true } ) )}>
			<Stack
				direction={{ xs: 'column', lg: 'row' }}
				alignItems='center'
				spacing={2}
				justifyContent='center'>
				<div/>
				<Typography variant='h5' sx={{ textAlign: 'center', fontWeight: 'normal', color: 'amethyst.main' }}>
					You are currently subscribed to the {subscription?.tier.name} plan. Your trial for some features will
					expire in
					<Chip
						label={(
							<Typography>
								{differenceInCalendarDays( new Date( subscription?.endDate ), new Date() )} Days
							</Typography>
						)}
						variant='alpha'
						color='warning'
						sx={{ mx: .5 }}
					/> Please refer to our plan page for more details.
				</Typography>
				<Button
					variant='text'
					color='inverted'
					onClick={() => showModal( SubscriptionModal, { variant: 'fullPageDialog' } )}>
					View plans
				</Button>
			</Stack>
		</GlobalBannerWrapper>
	);
}

