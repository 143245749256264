import PageLinkComponent from '@/components/page/linkComponent';
import { DeliveryDiningRounded as DeliveryDiningRoundedIcon } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { useRouter } from 'next/router';

export default function DriversButton() {
	const router = useRouter();
	const isDriver = router.asPath.split( '/' )[ 3 ] === 'drivers';
	
	return (
		<Tooltip title='Manage Drivers' placement='bottom'>
			<IconButton
				component={PageLinkComponent}
				href='/dashboard/commerce/drivers'
				color={isDriver ? 'primary' : 'default'}>
				<DeliveryDiningRoundedIcon/>
			</IconButton>
		</Tooltip>
	);
}

