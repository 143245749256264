import { Button, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useNetworkState } from 'react-use';

export default function Offline() {
	const { online } = useNetworkState();
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	
	useEffect( () => {
		if ( online ) return;
		enqueueSnackbar( 'You are offline', {
			variant         : 'default',
			persist         : true,
			preventDuplicate: true,
			action          : ( key ) => (
				<Stack direction='row' spacing={2} p={2}>
					<Button variant='text' onClick={() => closeSnackbar( key )}>
						Dismiss
					</Button>
					<Button variant='contained' onClick={() => location.reload()}>
						Refresh
					</Button>
				</Stack>
			),
		} );
	}, [ online ] );
	
	return null;
}
