export const reqSSE = async ( url: string, onProgress: ( data: number ) => void ) => {
	await new Promise<void>( ( resolve, reject ) => {
		const eventSource = new EventSource( url );
		
		eventSource.onmessage = function( event ) {
			const message = JSON.parse( event.data );
			if ( message.status === 'INFO' ) {
				console.debug( message );
				onProgress( message.progress );
			}
			
			if ( message.status === 'ERROR' ) {
				console.error( message.error );
				eventSource.close();
				reject( message.error );
			}
			
			if ( message.progress ) {
				onProgress( message.progress );
			}
			
			if ( message.status === 'DONE' ) {
				eventSource.close();
				resolve();
			}
		};
		
		eventSource.onerror = function( err ) {
			console.error( err );
			eventSource.close();
			reject( 'unknown error' );
		};
	} );
};
