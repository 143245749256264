import StoreCart from '@/pages/p/store/cart/index';
import { useOrderPublicRead } from '@/pages/p/store/context';
import { useModal } from '@/providers/modal';
import { ShoppingCart as ShoppingCartIcon } from '@mui/icons-material';
import { Badge, Box, Fade, IconButton, Typography } from '@mui/material';

export default function CartButton() {
	const { showModal } = useModal();
	const { order, isFetching } = useOrderPublicRead();
	
	if ( !order?.lineItems?.length ) return null;
	
	return (
		<IconButton
			onClick={() => showModal( StoreCart, {
				variant: 'drawer',
				id     : 'cartDrawer',
				sx     : {
					'& .MuiDrawer-paper': {
						border     : 'none',
						borderLeft : 1,
						borderColor: 'divider',
					},
				},
			} )}>
			<Badge
				sx={{ '.MuiBadge-badge': { bgcolor: isFetching ? 'text.primary' : 'error.main' } }}
				badgeContent={(
					<Box>
						<Fade in timeout={900}>
							<Typography color='white'>
								{order.lineItems.map( ( li ) => li.quantity ).reduce( ( a, b ) => a + b )}
							</Typography>
						</Fade>
					</Box>
				)}>
				<ShoppingCartIcon/>
			</Badge>
		</IconButton>
	);
}
