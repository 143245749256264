import { useGraphQL } from '@/data';
import { mutateGraphQL } from '@/data/apollo';
import { UserRead, UserWrite } from '@/data/user.graphql';
import { auth } from '@/firebase/client';
import type { MutationUserWriteArgs } from '@/types/schema';
import { userAtom } from '@/utils/atoms';
import { isSuperAdmin } from '@/utils/isSuperAdmin';
import { Box, Button, Container, Stack, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { signOut } from 'firebase/auth';
import { useAtom } from 'jotai';
import lottie from 'lottie-web';
import React, { useEffect, useRef } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useTranslation } from 'react-i18next';
import { useAsyncEffect } from 'rooks';
import { CompleteSplash, useAutoSplash } from '../splash';
import Apollo from './apollo';
import { useCompanyId } from './useSessionStorageCompanyId';

export default function AuthProvider( { children } ) {
	return (
		<Apollo>
			<Main>
				{children}
			</Main>
		</Apollo>
	);
}

function Main( { children } ) {
	const { i18n } = useTranslation();
	const [ firebaseUser, isFbLoading, fbError ] = useAuthState( auth );
	const queryClient = useQueryClient();
	const { sessionCompanyId, setCompanyId } = useCompanyId();
	const NoConnection = useRef<HTMLDivElement>( null );
	const [ user, setUser ] = useAtom( userAtom );
	
	const { data, error: userError } = useGraphQL( {
		queryKey: [ 'user', firebaseUser?.uid ],
		query   : UserRead,
	}, {
		enabled  : Boolean( firebaseUser?.uid ),
		cacheTime: 0,
		staleTime: 0,
		onSuccess: async ( { userRead } ) => {
			setCompanyId( sessionCompanyId || userRead?.staffs[ 0 ]?.company.id || null );
			if ( userRead?.firstName ) return;
			
			// create user for the first time:
			const name = firebaseUser!.displayName?.split( ' ' );
			await mutateGraphQL<MutationUserWriteArgs>( {
				mutation : UserWrite,
				variables: {
					input: {
						email    : firebaseUser!.email || undefined,
						firstName: name?.[ 0 ],
						lastName : name?.[ 1 ],
						image    : firebaseUser!.photoURL || undefined,
					},
				},
			} );
			await queryClient.invalidateQueries( [ 'user' ] );
		},
	} );
	
	const error = userError || fbError;
	
	useEffect( () => {
		setUser( data?.userRead || undefined );
	}, [ data?.userRead, firebaseUser ] );
	
	const userLang = data?.userRead?.language;
	useAsyncEffect( async () => {
		if ( !userLang ) return;
		await i18n.changeLanguage( userLang );
	}, [ userLang ] );
	
	useEffect( () => {
		if ( !isSuperAdmin( user ) && user?.staffs.length === 1 ) {
			setCompanyId( user?.staffs[ 0 ].company.id );
		}
	}, [ user ] );
	
	useEffect( () => {
		if ( !error ) return;
		console.error( error );
		lottie.loadAnimation( {
			container: NoConnection?.current as HTMLDivElement,
			renderer : 'svg',
			loop     : true,
			autoplay : true,
			path     : 'https://www.invoiss.com/lottie/no-connection.json',
		} );
	}, [ error ] );
	
	const isLoadingUser = isFbLoading || !!firebaseUser?.uid && user === undefined;
	
	useAutoSplash( isLoadingUser, 1000 );
	
	if ( error ) {
		console.error( error );
		return (
			<Container maxWidth='md'>
				<CompleteSplash/>
				<Stack spacing={2} mt={5} textAlign='center'>
					<Box ref={NoConnection} sx={{ width: '100%', height: 300 }}/>
					{/*<Typography variant='h1'>*/}
					{/*	Oops! We hit a small bump.*/}
					{/*</Typography>*/}
					<Typography color='warning' style={{ fontSize: '1.7rem' }}>
						{/*Try clearing your browser cache or open a new incognito window. We apologize for the inconvenience.*/}
						Apologies! We're currently experiencing a partial outage. Our team is actively working to resolve the
						issue as quickly as possible.
						<br/>
						{/*If the issue persists, we're here to help:*/}
						<br/>
						{/*<PageLink href='tel:18449999203'>Call Us: 844-999-9203</PageLink> (Option 2) or&nbsp;*/}
						{/*<PageLink href='https://www.invoiss.com/help' target='_blank'>Chat with a Specialist</PageLink> at*/}
						{/*invoiss.com/help.*/}
					</Typography>
					<Box>
						<Button
							size='large'
							variant='contained'
							color='primary'
							sx={{ width: 150 }}
							onClick={() => location.reload()}>
							Try again!
						</Button>
					</Box>
				</Stack>
			</Container>
		);
	}
	
	if ( isLoadingUser ) return null;
	
	return children;
}

export async function logout() {
	await signOut( auth );
}
