import Intercom from '@intercom/messenger-js-sdk';
import { GlobalStyles } from '@mui/material';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

export default function Intercom_() {
	const router = useRouter();
	const path = router.route.split( '/' )[ 1 ];
	const [ show, setShow ] = useState( false );
	
	useEffect( () => {
		Intercom( { app_id: process.env.NEXT_PUBLIC_INTERCOM_APP_ID! } );
	}, [] );
	
	useEffect( () => {
		if (
			path !== 'dashboard'
			&& path !== 'client'
			&& path !== 'driver'
			&& path !== 'tender'
			&& path !== 'admin'
			&& path !== 'p'
		) {
			setShow( true );
		} else {
			setShow( false );
		}
	}, [ path ] );
	
	return (
		<GlobalStyles
			styles={{
				'#intercom-container': {
					display: show ? 'block' : 'none',
				},
			}}
		/>
	);
}
