import { ReactNode, useEffect, useState } from 'react';

export interface MessageType {
	id: string,
	title?: ReactNode,
	content: ReactNode, /*| ( ( props: any ) => JSX.Element )*/
	
	[ key: string ]: any
	
}

interface UseDismissibleMessageResult {
	messages: MessageType[],
	dismissMessage: ( id: string ) => void
}

export const useDismissibleMessage = (
	initialMessages: MessageType[],
	localStorageID: string ): UseDismissibleMessageResult => {
	
	const [ messages, setMessages ] = useState<MessageType[]>( [] );
	
	useEffect( () => {
		const dismissedMessageIds = JSON.parse( localStorage.getItem( localStorageID ) || '[]' );
		const filteredMessages = initialMessages.filter( ( message ) => !dismissedMessageIds.includes( message.id ) );
		setMessages( filteredMessages );
		
		localStorage.setItem( localStorageID, JSON.stringify( dismissedMessageIds.filter( ( id ) => initialMessages.some( ( message ) => message.id === id ) ) ),
		);
	}, [ initialMessages ] );
	
	const dismissMessage = ( id: string ) => {
		setMessages( ( prevMessages ) => prevMessages.filter( ( message ) => message.id !== id ) );
		
		const dismissedMessageIds = JSON.parse( localStorage.getItem( localStorageID ) || '[]' );
		localStorage.setItem( localStorageID, JSON.stringify( [ ...dismissedMessageIds, id ] ) );
	};
	
	return { messages, dismissMessage };
};
