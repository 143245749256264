import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

type State = {
	theme: string,
	userTimeout: number,
	timeoutTime: number
};

const initialState: State = {
	theme      : 'default',
	userTimeout: 60 * 24 * 7 * 365 * 1000,
	timeoutTime: +new Date() + 1000 * 60 * ( 60 * 24 * 7 * 365 * 1000 ),
};

const mainSlice = createSlice( {
	name    : 'main',
	initialState,
	reducers: {
		setTheme( state, { payload }: PayloadAction<string> ) {
			state.theme = payload;
		},
		setUserTimeout( state, { payload }: PayloadAction<number> ) {
			state.userTimeout = payload;
			state.timeoutTime = +new Date() + 1000 * 60 * payload;
		},
		setTimeOutTime( state ) {
			state.timeoutTime = +new Date() + 1000 * 60 * state.userTimeout;
		},
	},
} );

export default mainSlice.reducer;
export const
	setTheme       = mainSlice.actions.setTheme,
	setUserTimeout = mainSlice.actions.setUserTimeout,
	setTimeoutTime = mainSlice.actions.setTimeOutTime;

