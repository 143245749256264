import { getClientMobileDashboardItems } from '@/baseline/navigation/menuItems';
import ChatRoomComponent from '@/components/chat/chatRoomComponent';
import { queryGraphQL } from '@/data/apollo';
import { ClientRead } from '@/data/management/client.graphql';
import { UserExists } from '@/data/user.graphql';
import NoUserMatchMessage from '@/modals/noUserMatchMessageModal';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useModal } from '@/providers/modal';
import type { QueryClientReadArgs, QueryUserExistsArgs } from '@/types/schema';
import type { Theme } from '@mui/material';
import { AppBar, Box, Button, Container, Stack, useMediaQuery, useTheme } from '@mui/material';
import { useRouter } from 'next/router';
import React, { Fragment } from 'react';
import { useAsyncEffect } from 'rooks';
import { validate } from 'uuid';
import SettingsButton from '../dashboard/main/settingsButton';

export function ClientLayout( { children } ) {
	const theme = useTheme();
	const router = useRouter();
	const { user } = useUserInfo();
	const { showModal } = useModal();
	const isMobile = useMediaQuery<Theme>( ( { breakpoints } ) => breakpoints.down( 'sm' ) );
	const clientMenuItems = getClientMobileDashboardItems();
	
	if ( !user ) router.push( {
		pathname: '/login',
		query   : {
			redirect: router.asPath as string,
		},
	} );
	
	useAsyncEffect( async () => {
		if ( user ) return;
		
		let route = '/signup', query;
		if ( router.query.id && router.query.month ) {
			const { clientRead } = await queryGraphQL<QueryClientReadArgs>( {
				query    : ClientRead,
				variables: { id: router.query.id as string },
			} );
			
			const newClient = clientRead?.name || clientRead?.contact;
			
			if ( newClient ) {
				query = {
					firstName: newClient?.split( ' ' )[ 0 ],
					lastName : newClient?.split( ' ' )[ 1 ],
					email    : clientRead?.email,
				};
			}
			const { userExists } = await queryGraphQL<QueryUserExistsArgs>( {
				query    : UserExists,
				variables: { email: clientRead.email },
			} );
			route = userExists ? '/login' : '/signup';
		}
		
		await router.push( {
			pathname: route,
			query   : {
				redirect: router.asPath,
				...query,
			},
		} );
	}, [ user ] );
	
	if ( isMobile ) return <Fragment>{children}</Fragment>;
	
	if ( !user ) return null;
	
	return (
		<Fragment>
			<ChatRoomComponent clientView/>
			<AppBar
				color='inherit'
				position='static'
				sx={{
					'border'       : 0,
					'borderBottom' : 1,
					'borderColor'  : 'divider',
					'py'           : 1,
					'bgcolor'      : 'transparent',
					'boxShadow'    : 'none !important',
					'.AppbarButton': {
						height      : 27,
						boxShadow   : 'none !important',
						border      : '0px !important',
						transition  : '.4s all',
						borderRadius: '15px !important',
					},
				}}>
				<Container maxWidth='xl'>
					<Stack spacing={1} direction='row' sx={{ display: 'flex', flexGrow: 1 }}>
						{clientMenuItems?.map( ( menuItem, index ) => {
							const selectedRoute = menuItem.href === '/client'
								? router.route === menuItem.href
								: router.route.includes( menuItem.href );
							return (
								<Button
									key={index}
									className='AppbarButton'
									sx={{
										'color'  : selectedRoute && '#ffffff !important',
										'bgcolor': selectedRoute
											? `${theme.palette.primary.main} !important`
											: 'transparent !important',
										':hover' : { bgcolor: !selectedRoute && `${theme.palette.divider} !important` },
									}}
									onClick={async () => {
										const clientId = router.asPath.split( '/' )[ 2 ];
										
										if ( validate( clientId ) ) {
											try {
												const { clientRead } = await queryGraphQL<QueryClientReadArgs>( {
													query    : ClientRead,
													variables: {
														id: clientId as string,
													},
												} );
												
												const newClient = clientRead?.name || clientRead?.contact;
												const firstName = newClient?.split( ' ' )[ 0 ];
												const lastName = newClient?.split( ' ' )[ 1 ];
												const email = clientRead?.email;
												
												const { userExists } = await queryGraphQL<QueryUserExistsArgs>( {
													query    : UserExists,
													variables: {
														email,
													},
												} );
												
												if ( !user && !userExists ) {
													await router.push( {
														pathname: '/signup',
														query   : { firstName, lastName, email },
													}, '/signup' );
												} else if ( userExists && !user ) {
													await router.push( {
														pathname: '/login',
														query   : { email },
													}, '/login' );
												} else {
													if ( user.email === email ) {
														await router.push( menuItem.href );
													} else {
														showModal( NoUserMatchMessage, { maxWidth: 'xs' }, {} );
													}
												}
											} catch ( e ) {
												return null;
											}
										} else {
											if ( user ) {
												await router.push( menuItem.href );
											} else {
												await router.push( '/login' );
											}
										}
									}}>
									{menuItem.name}
								</Button>
							);
						} )}
						<Box display='flex' flexGrow={1}/>
						<SettingsButton title='Profile & Settings' href='/settings'/>
					</Stack>
				</Container>
			</AppBar>
			{children}
		</Fragment>
	);
}
