import { ShoppingBagRounded as ShoppingBagRoundedIcon } from '@mui/icons-material';
import { Button, Tooltip } from '@mui/material';

export default function MyOrdersButton() {
	
	return (
		<Tooltip title='See my Orders'>
			<Button
				startIcon={<ShoppingBagRoundedIcon/>}
				sx={{
					'borderRadius': '20px !important',
					'height'      : '28px !important',
					'bgcolor'     : ( theme ) => theme.palette.divider + ' !important',
					'boxShadow'   : 'none !important',
					':hover'      : {
						bgcolor: 'divider',
						filter : 'brightness(110%)',
					},
				}}
				size='small'
				onClick={() => {
					window.open( '/client/orders', '_blank' );
				}}>
				My Orders
			</Button>
		</Tooltip>
	);
}
