import { Box, Chip, Tooltip } from '@mui/material';
import axios from 'axios';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useAsyncEffect } from 'rooks';
import useShowSnackBarAfterReload from '../../../hooks/useShowSnackBarAfterReload';

export default function UpdateNotification() {
	const [ showUpdateNotification, setShowUpdateNotification ] = useState( false );
	const router = useRouter();
	const showSnackBarAfterReload = useShowSnackBarAfterReload();
	
	function reloadAndShowNotification() {
		location.reload();
		// showSnackBarAfterReload( 'The platform has been updated successfully.', { variant: 'success' } );
	}
	
	// This function checks the package.json by making a request to the server and gets the version from localStorage
	// and compares the two. If they are not equal, it sets it in localState, and lastly it will set it in localStorage.
	const checkPackageJsonVersionAndSetItInLocalStorage = async () => {
		try {
			const localStorageAppVersion = localStorage.getItem( 'app_version' );
			const response = await axios.get( '/api/version' ); // get the package.json version
			const { version: serverSidePackageJsonVersion } = response.data;
			
			// if localStorageAppVersion exists and is not equal to serverSidePackageJsonVersion, show update notification
			if ( localStorageAppVersion && localStorageAppVersion !== serverSidePackageJsonVersion ) {
				setShowUpdateNotification( true );
			}
			localStorage.setItem( 'app_version', serverSidePackageJsonVersion );
		} catch ( error ) {
			console.error( 'Error checking for updates:', error );
		}
	};
	
	// Normally the app doesn't reload when you change route.
	// And the only way to receive the new updates is to reload the web browser.
	// So this useEffect listens for any route change and reloads the browser if an update is available.
	useEffect( () => {
		const handleRouteChange = () => {
			if ( showUpdateNotification ) {
				reloadAndShowNotification();
			}
		};
		
		router.events.on( 'routeChangeStart', handleRouteChange );
		
		return () => {
			router.events.off( 'routeChangeStart', handleRouteChange );
		};
	}, [ router, showUpdateNotification ] );
	
	// This useEffect checks for updates every 3 minutes and sets the package.json version in localStorage
	// It will also clear the interval when the component unmounts because we don't want to keep checking for updates.
	useAsyncEffect( async () => {
		await checkPackageJsonVersionAndSetItInLocalStorage();
		
		const interval = setInterval( checkPackageJsonVersionAndSetItInLocalStorage, 180000 ); // Checks every 3 minutes
		return () => clearInterval( interval );
	}, [] );
	
	// If showUpdateNotification is false, don't show the update notification.
	if ( !showUpdateNotification ) return null;
	
	return (
		<Tooltip title='New Update Available, Click to Reload'>
			<Chip
				label='Update'
				color='success'
				variant='outlined'
				icon={(
					<Box className='ripple-dot' sx={{ bgcolor: '#379a29 !important' }}>
						<Box
							className='ripple-effect'
							sx={{ bgcolor: '#379a29 !important', borderColor: '#379a29 !important' }}
						/>
					</Box>
				)}
				size='small'
				sx={{ px: 1 }}
				onClick={reloadAndShowNotification}
			/>
		</Tooltip>
	);
}

