import MessagesSection from '@/pages/dashboard/chat/messagesBox';
import { useGraphQLChatUsers } from '@/pages/dashboard/chat/useGraphQLChatUsers';
import { CheckPermissions } from '@/providers/auth/usePermissions';
import { chatSettingsAtom } from '@/utils/atoms';
import { Grow, Paper, type Theme, useMediaQuery } from '@mui/material';
import { useAtom } from 'jotai/index';
import ChatBoxToolbar from './chatBoxToolbar';

export default function ChatBox( { roomId, clientView = false }: { roomId: string, clientView: boolean } ) {
	useGraphQLChatUsers( { clientView } );
	const [ chatSettings ] = useAtom( chatSettingsAtom );
	const isMobile = useMediaQuery<Theme>( ( { breakpoints } ) => breakpoints.down( 'sm' ) );
	
	if ( isMobile ) return null;
	
	return (
		<CheckPermissions permissions={[ 'USER' ]}>
			<Grow in>
				<Paper
					sx={{
						width                  : 450,
						minHeight              : !chatSettings.minimizeChat ? '50%' : 'unset',
						height                 : !chatSettings.minimizeChat ? '70%' : 'unset',
						borderBottomLeftRadius : 0,
						borderBottomRightRadius: 0,
						display                : 'flex',
						flexDirection          : 'column',
						overflow               : 'hidden',
						flex                   : 1,
						boxShadow              : 25,
						position               : 'fixed',
						right                  : 80,
						left                   : 'unset',
						bottom                 : 0,
						zIndex                 : 1000,
					}}>
					<ChatBoxToolbar clientView={clientView}/>
					{!chatSettings.minimizeChat ? <MessagesSection clientView={clientView} roomId={roomId}/> : null}
				</Paper>
			</Grow>
		</CheckPermissions>
	);
}
