import { GetImageName } from '@/components/fileUploading/attachment';
import PageLinkComponent from '@/components/page/linkComponent';
import { useMenu } from '@/providers/menu';
import { useModal } from '@/providers/modal';
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
import {
	Avatar,
	Badge,
	Box,
	Divider,
	Fade,
	IconButton,
	ListItemButton,
	ListItemText,
	MenuItem,
	MenuList,
	Stack,
} from '@mui/material';
import { useAtom } from 'jotai/index';
import { toUpper } from 'lodash-es';
import { useRouter } from 'next/router';
import React from 'react';
import { auth } from '../../../firebase/client';
import { Room, useRoomMessages } from '../../../firebase/firestore';
import safeDateFns from '../../../helpers/safeFormat';
import { RoomType } from '../../../types/chatRoom';
import { clientsMapAtom, companyMapAtom, staffsMapAtom } from '../../../utils/atoms';
import ModalRoomForm from './newRoomModal';
import { transformFirebaseDate } from './utils';

const getFirstMessageContent = ( message ) => message?.content || GetImageName( message?.attachment || '' );

export default function RoomItem( { room, clientView = false }: { room: Room, clientView?: boolean } ) {
	const currentUserId = auth.currentUser?.uid;
	const router = useRouter();
	const { showMenu } = useMenu();
	const { showModal } = useModal();
	const messages = useRoomMessages( room.id );
	const [ clientsAtom ] = useAtom( clientsMapAtom );
	const [ staffsAtom ] = useAtom( staffsMapAtom );
	const [ companyAtom ] = useAtom( companyMapAtom );
	
	const unreadMessages = messages?.filter( ( message ) => !message.readBy?.includes( currentUserId ) );
	const firstStaff = staffsAtom[ room.staffs?.[ 0 ] ];
	const client = clientsAtom[ room.client ];
	
	const lastMessage = messages?.[ 0 ];
	const mostRecentPerson = lastMessage?.from
		? lastMessage.from === 'AI'
			? { firstName: 'AI', image: '/images/invoiss-ai.png' }
			: staffsAtom[ lastMessage?.from ]
		: null;
	
	const internalTitle = messages?.length
		? `${mostRecentPerson?.firstName}: ${getFirstMessageContent( lastMessage )}`
		: 'No Messages Yet';
	const clientVariations = client?.name || client?.contact || client?.email;
	const externalTitle = `Client: ${clientVariations || '-'}`;
	const roomCompany = companyAtom[ room.company ];
	const roomTitle = clientView ? roomCompany?.name : room.type === RoomType.EXTERNAL ? externalTitle : internalTitle;
	
	const avatarContent = clientView
		? roomCompany?.name?.[ 0 ]
		: room.type === RoomType.EXTERNAL
			? `${clientVariations?.at( 0 )}${clientVariations?.at( 1 )}`
			: `${firstStaff?.firstName?.at( 0 )}${firstStaff?.lastName?.at( 0 )}`;
	const sent1MinuteAgo = lastMessage && !lastMessage?.createdAt ? true : safeDateFns.differenceInMilliseconds(
		new Date(),
		transformFirebaseDate( lastMessage?.createdAt ),
	) < 60000;
	
	const subtitle = sent1MinuteAgo
		? 'Less than a minute ago'
		: lastMessage?.updatedAt
			? safeDateFns.formatDistanceToNow( transformFirebaseDate( lastMessage?.updatedAt ) ) + ' ago'
			: '-';
	
	return (
		<Fade key={room.id} in timeout={1000}>
			<Box>
				<ListItemButton
					shallow
					selected={router.query.id === room.id}
					component={PageLinkComponent}
					href={clientView
						? `/client/chat/${room.id}`
						: `/dashboard/chat/${room.id}`}
					sx={{ m: 1 }}>
					<Badge
						overlap='circular'
						anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
						invisible={clientView || room.attendees?.length <= 1}
						badgeContent={(
							<Avatar
								sx={{
									color       : 'text.secondary',
									fontSize    : 12,
									width       : 20,
									height      : 20,
									border      : 2,
									borderRadius: 1.5,
									borderColor : 'background.paper',
								}}
								variant='rounded'>
								{room.attendees?.length}
							</Avatar>
						)}>
						<Avatar
							sx={{ borderRadius: 2, width: 30, height: 30, fontSize: 12 }}
							variant='rounded'
							src={clientView ? roomCompany?.logo : room.type === RoomType.EXTERNAL
								? client?.image
								: mostRecentPerson?.image}>
							{toUpper( avatarContent )}
						</Avatar>
					</Badge>
					<ListItemText
						primary={roomTitle}
						secondary={subtitle}
						primaryTypographyProps={{ noWrap: true, sx: { color: 'text.secondary' } }}
						secondaryTypographyProps={{ noWrap: true }}
						sx={{ ml: 1 }}
					/>
					<Stack direction='row' spacing={1} alignItems='center'>
						<Badge
							color='primary'
							badgeContent={unreadMessages?.length}
							invisible={unreadMessages?.length === 0}
							overlap='circular'
							anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
						/>
						{!clientView && room.type === RoomType.EXTERNAL && (
							<IconButton
								onClick={( e ) => {
									e.preventDefault();
									showMenu( ( { closeMenu } ) => (
										<MenuList>
											<MenuItem
												key='edit'
												onClick={async ( e ) => {
													e.stopPropagation();
													showModal( ModalRoomForm, { maxWidth: 'sm' }, { room: room } );
													closeMenu();
												}}>
												Edit
											</MenuItem>
										</MenuList>
									), e.currentTarget, { MenuListProps: { sx: { width: 150 } } } );
								}}>
								<MoreVertIcon/>
							</IconButton>
						)}
					</Stack>
				</ListItemButton>
				<Divider/>
			</Box>
		</Fade>
	);
}
