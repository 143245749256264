import useSubscription from '@/baseline/subscription/useSubscription';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import type { Theme } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { useSnackbar } from 'notistack';
import { type ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import DesktopNavigation from '../baseline/navigation/desktop';
import MobileNavigation from '../baseline/navigation/mobile';
import SubscriptionModal from '../baseline/subscription';

if ( typeof window !== 'undefined' ) defineCustomElements( window ).then();

function BlockLayout( { active, staffDisabled }: { active: boolean, staffDisabled?: boolean } ) {
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	
	useEffect( () => {
		if ( active && staffDisabled ) {
			enqueueSnackbar( t( 'common:staff-disabled-alert' ), {
				variant         : 'warning',
				autoHideDuration: null,
				style           : { whiteSpace: 'pre-line' },
				anchorOrigin    : { vertical: 'top', horizontal: 'center' },
			} );
		}
	}, [ active, staffDisabled ] );
	
	return (
		<SubscriptionModal hasNavbar showBackIcon={false}/>
	);
}

export function MainLayout( { children, applySubscription = false }: {
	children: ReactNode,
	applySubscription?: boolean
} ) {
	const wide = useMediaQuery<Theme>( ( { breakpoints } ) => breakpoints.up( 'sm' ) );
	const { active, staffDisabled } = useSubscription();
	
	if ( applySubscription && ( !active || staffDisabled ) ) return (
		<BlockLayout active={active} staffDisabled={staffDisabled}/>
	);
	
	if ( wide ) return <DesktopNavigation>{children}</DesktopNavigation>;
	
	return <MobileNavigation>{children}</MobileNavigation>;
}
