import { Box, Collapse, IconButton, Stack, StackProps } from '@mui/material';
import { CloseIcon } from 'next/dist/client/components/react-dev-overlay/internal/icons/CloseIcon';
import { useEffect, useState } from 'react';

export default function GlobalBannerWrapper( {
	color = 'alpha.primary',
	show,
	onClose,
	children,
	...props
}: {
	color?: string,
	show: boolean,
	onClose?: () => void,
	children: React.ReactNode
} & StackProps ) {
	const [ internalShow, setInternalShow ] = useState( false );
	
	useEffect( () => {
		setTimeout( () => {
			setInternalShow( true );
		}, 1000 );
	}, [] );
	
	return (
		<Collapse in={internalShow && show}>
			<Stack
				direction='row'
				alignItems='center'
				justifyContent='space-between'
				{...props}
				sx={{ p: 2, bgcolor: color, ...props?.sx }}>
				<Box/>
				{children}
				{onClose ? (
					<IconButton onClick={onClose}>
						<CloseIcon/>
					</IconButton>
				) : <Box/>}
			</Stack>
		</Collapse>
	);
}

