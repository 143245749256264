import Form from '@/components/form';
import AsyncLoadingButton from '@/components/form/asyncLoading/asyncLoadingButton';
import LocationSelect from '@/pages/formSelects/locationSelect';
import { Location } from '@/types/schema';
import { Stack } from '@mui/material';
import React from 'react';

export default function SelectLocation( { goToNextStep }: { goToNextStep: ( location: Location ) => void } ) {
	
	return (
		<Form<{ location: Location }>
			noClose
			initialValues={{ location: null }}
			onSubmit={( values ) => goToNextStep( values.location )}>
			{( formik ) => (
				<Stack spacing={1}>
					<LocationSelect
						name='location'
						label='Clover Locations'
						variables={{ options: { filter: { gateway: { active: true, external: 'CLOVER' } } } }}
						onAdd={undefined}
					/>
					<AsyncLoadingButton
						disabled={!formik.values.location}
						variant='contained'
						color='primary'
						loading={formik.isSubmitting}
						onClick={formik.handleSubmit}>
						Continue
					</AsyncLoadingButton>
				</Stack>
			)}
		</Form>
	);
}
