import { axiosClient } from '@/data';
import { db, ENV, useRooms } from '@/firebase/firestore';
import ModalRoomForm from '@/pages/dashboard/chat/newRoomModal';
import { useCompanyId } from '@/providers/auth/useSessionStorageCompanyId';
import { useModal } from '@/providers/modal';
import { RoomType } from '@/types/chatRoom';
import { AddComment as AddCommentIcon } from '@mui/icons-material';
import { collection, getDocs, limit, orderBy, query } from 'firebase/firestore';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import AsyncLoadingButton from './form/asyncLoading/asyncLoadingButton';

export default function CreateRoomButton( { type, clientView = false }: { type?: RoomType, clientView?: boolean } ) {
	const { t } = useTranslation();
	const { sessionCompanyId } = useCompanyId();
	const router = useRouter();
	const { showModal } = useModal();
	const { rooms: aiRooms } = useRooms( sessionCompanyId, RoomType.AI, false, { limit: 1 } );
	
	async function createRoomAndRedirect() {
		const { data } = await axiosClient.post( '/api/user/chat/AI/createRoom' );
		return router.push( `/dashboard/chat/${data.roomId}`, undefined, { shallow: true } );
	}
	
	return (
		<AsyncLoadingButton
			sx={{ m: 'auto', my: 1, width: '90%', display: 'flex' }}
			startIcon={<AddCommentIcon/>}
			variant='contained'
			color='primary'
			onClick={async () => {
				if ( type === 'AI' ) {
					const lastRoom = aiRooms?.[ 0 ];
					if ( !lastRoom ) return createRoomAndRedirect();
					const q = query(
						collection( db, 'envs', ENV, 'rooms', lastRoom.id, 'messages' ),
						orderBy( 'createdAt', 'desc' ),
						limit( 1 ),
					);
					const querySnapshot = await getDocs( q );
					if ( querySnapshot.empty ) return router.push( `/dashboard/chat/${lastRoom.id}`, undefined, { shallow: true } );
					return createRoomAndRedirect();
				}
				showModal( ModalRoomForm, { maxWidth: 'sm' }, {
					clientView: clientView,
					onSubmit  : async ( roomId ) => {
						if ( clientView ) {
							await router.push( `/client/chat/${roomId}`, undefined, { shallow: true } );
							return;
						}
						await router.push( `/dashboard/chat/${roomId}`, undefined, { shallow: true } );
					},
				} );
			}}>
			{type === 'AI' ? 'New Chat' : t( 'common:create-room' )}
		</AsyncLoadingButton>
	);
}
