import { Box } from '@mui/material';
import { useAtom } from 'jotai';
import React, { Suspense } from 'react';
import { chatSettingsAtom } from '../../utils/atoms';
import Loading from '../loading';
import ChatBox from './chatBox';

export default function ChatRoomComponent( { clientView = false }: { clientView?: boolean } ) {
	const [ chatSettings ] = useAtom( chatSettingsAtom );
	
	if ( !chatSettings.windowChat ) return null;
	
	return (
		<Box>
			<Suspense fallback={<Loading/>}>
				<ChatBox clientView={clientView} roomId={chatSettings.chatRoomID}/>
			</Suspense>
		</Box>
	);
}
