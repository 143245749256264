import { KeyboardCommandKeyRounded as KeyboardCommandKeyRoundedIcon } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';

export default function SearchModalFooter() {
	return (
		<Stack
			direction='row'
			alignItems='center'
			justifyContent='space-between'
			spacing={3}
			p={1}
			sx={{ width: '100%' }}>
			<Box/>
			{/*<Stack spacing={1} direction='row' alignItems='center'>*/}
			{/*	<NorthRoundedIcon*/}
			{/*		sx={{*/}
			{/*			p           : .5,*/}
			{/*			borderRadius: 1,*/}
			{/*			bgcolor     : 'divider',*/}
			{/*			color       : 'text.secondary',*/}
			{/*		}}*/}
			{/*	/>*/}
			{/*	<SouthRoundedIcon*/}
			{/*		sx={{*/}
			{/*			p           : .5,*/}
			{/*			borderRadius: 1,*/}
			{/*			bgcolor     : 'divider',*/}
			{/*			color       : 'text.secondary',*/}
			{/*		}}*/}
			{/*	/>*/}
			{/*	<Typography color='text.secondary' fontWeight='500'>*/}
			{/*		to navigate*/}
			{/*	</Typography>*/}
			{/*</Stack>*/}
			{/*<Stack spacing={1} direction='row' alignItems='center'>*/}
			{/*	<KeyboardReturnRoundedIcon*/}
			{/*		sx={{*/}
			{/*			p           : .5,*/}
			{/*			borderRadius: 1,*/}
			{/*			bgcolor     : 'divider',*/}
			{/*			color       : 'text.secondary',*/}
			{/*		}}*/}
			{/*	/>*/}
			{/*	<Typography color='text.secondary' fontWeight='500'>*/}
			{/*		to enter*/}
			{/*	</Typography>*/}
			{/*</Stack>*/}
			<Stack spacing={1} direction='row' alignItems='center'>
				<KeyboardCommandKeyRoundedIcon
					sx={{
						p           : .5,
						borderRadius: 1,
						bgcolor     : 'divider',
						color       : 'text.secondary',
					}}
				/>
				<Typography color='text.secondary' fontWeight='500'>
					to exit
				</Typography>
			</Stack>
		</Stack>
	);
}
