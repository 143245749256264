import { CssBaseline, ThemeProvider } from '@mui/material';
import type { ReactNode } from 'react';
import { createContext, useContext, useEffect, useState } from 'react';
import { darkTheme, lightTheme } from './theme/themes';

type C = ( mode: 'light' | 'dark' ) => void;

const StaticThemeContext = createContext<C>( () => null );

export default function StaticThemeProvider( { children }: { children: ReactNode } ) {
	const [ mode, setMode ] = useState( 'light' );
	
	return (
		<StaticThemeContext.Provider value={setMode}>
			<ThemeProvider theme={mode === 'light' ? lightTheme : darkTheme}>
				<CssBaseline/>
				{children}
			</ThemeProvider>
		</StaticThemeContext.Provider>
	);
}

export function useStaticTheme( mode: 'light' | 'dark' ) {
	const setMode = useContext( StaticThemeContext );
	
	useEffect( () => {
		setMode( mode );
	}, [ mode ] );
}

