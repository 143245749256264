import useGetDeviceInfo from '@/hooks/useGetDeviceInfo';
import RegisterAppModal from '@/modals/registerApp';
import { useModal } from '@/providers/modal';
import { dismissibleBannerAtoms } from '@/utils/atoms';
import { Button, Stack, Typography } from '@mui/material';
import { useAtom } from 'jotai/index';
import GlobalBannerWrapper from './wrapper';

export default function CloverDeviceBanner() {
	const { isCloverDevice } = useGetDeviceInfo();
	const [ globalBanners, setGlobalBanners ] = useAtom( dismissibleBannerAtoms );
	const { showModal } = useModal();
	
	return (
		<GlobalBannerWrapper
			show={!globalBanners.hideCloverDeviceBanner && isCloverDevice}
			onClose={() => setGlobalBanners( ( prev ) => ( { ...prev, hideCloverDeviceBanner: true } ) )}>
			<Stack
				direction={{ xs: 'column', lg: 'row' }}
				alignItems='center'
				justifyContent='center'>
				<div/>
				<Typography variant='h5' sx={{ textAlign: 'center', fontWeight: 'normal', color: 'warning.main' }}>
					Use your Clover device to create invoices, estimates much faster
				</Typography>
				<Button
					sx={{ ml: 1 }}
					onClick={() => showModal( RegisterAppModal, { maxWidth: 'sm' } )}>
					Learn more
				</Button>
			</Stack>
		</GlobalBannerWrapper>
	);
}

