import { Box, Skeleton, Stack } from '@mui/material';

export default function GlobalSearchSkeleton( { length = 6 }: { length?: number } ): JSX.Element {
	return (
		<Box>
			<Stack
				spacing={1}
				p={1}>
				<Skeleton
					animation='wave'
					width={50}
					height={10}
				/>
				{[ ...new Array( length ) ].map( ( _, index ) => (
					<Stack
						key={index}
						direction='row'
						alignItems='center'
						spacing={1}
						sx={{ p: .5 }}>
						<Skeleton
							animation='wave'
							width={30}
							height={45}
							sx={{ borderRadius: '100%' }}
						/>
						<Stack sx={{ pl: 2 }} width='100%'>
							<Skeleton
								animation='wave'
								width={100}
								height={10}
							/>
							<Skeleton
								animation='wave'
								width={200}
								height={10}
								sx={{ mt: 1 }}
							/>
						</Stack>
					</Stack>
				) )}
			</Stack>
		</Box>
	);
}

