import HelpButton from '@/baseline/navigation/desktop/helpButton';
import LanguageButton from '@/baseline/navigation/desktop/languageButton';
import ProfileButton from '@/baseline/navigation/desktop/profileButton';
import { usePartner } from '@/hooks/useSetPartnerInAtom';
import useUserInfo from '@/providers/auth/useUserInfo';
import { AppBar, Box, Container, Stack, Typography } from '@mui/material';
import { capitalize } from 'lodash-es';
import { useRouter } from 'next/router';
import { ReactNode } from 'react';
import { useWindowSize } from 'react-use';
import { useAsyncEffect } from 'rooks';

export default function PartnerLayout( { children }: { children: ReactNode } ) {
	const { height } = useWindowSize();
	const { user } = useUserInfo();
	const router = useRouter();
	const { partner } = usePartner();
	
	useAsyncEffect( async () => {
		if ( !user.salesAgent?.id ) await router.replace( '/welcome' );
	}, [ user ] );
	
	if ( !user.salesAgent?.id ) return null;
	
	return (
		<Box
			pl='env(safe-area-inset-left)'
			pr='env(safe-area-inset-right)'
			minHeight={`min(calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom)), ${height}px)`}
			sx={{
				bgcolor: 'background.default',
			}}>
			<AppBar position='static' color='inherit' sx={{ boxShadow: 0, bgcolor: 'transparent', border: 0 }}>
				<Container maxWidth='xl' sx={{ py: 1 }}>
					<Stack
						direction='row'
						alignItems='center'
						spacing={2}>
						<Typography variant='h3'>
							{capitalize( partner?.name )}
						</Typography>
						<Box display='flex' flexGrow={1}/>
						<Stack
							direction='row'
							alignItems='center'
							spacing={2}>
							<HelpButton/>
							<LanguageButton/>
							<ProfileButton
								removeDriverMode
								removeClientMode
								removeCompanyMode
								profileRoute='/partner/settings'
							/>
						</Stack>
					</Stack>
				</Container>
			</AppBar>
			{children}
		</Box>
	);
}

