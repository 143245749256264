import { ResponsiveModalContainer } from '@/providers/modal/responsiveModal';
import { Box, Stack, Typography } from '@mui/material';

export default function RegisterAppModal() {
	
	return (
		<ResponsiveModalContainer title='Use your clover device'>
			<Stack spacing={1} sx={{ '.MuiTypography-root': { fontSize: 16 } }}>
				<Typography variant='h5' pb={3}>
					Create estimates and invoices much faster using your device.
				</Typography>
				<Stack direction='row' sx={{ alignItems: 'center' }}>
					<Box
						className='center'
						sx={{
							bgcolor     : '#ffffff',
							borderRadius: '50%',
							height      : 30,
							mr          : 1,
							width       : 30,
						}}>
						<Typography color='#000000' variant='h5'>1</Typography>
					</Box>
					<Typography>Navigate to the Register app</Typography>
				</Stack>
				<Stack direction='row' sx={{ alignItems: 'center' }}>
					<Box
						className='center'
						sx={{
							bgcolor     : '#ffffff',
							borderRadius: '50%',
							height      : 30,
							mr          : 1,
							width       : 30,
						}}>
						<Typography color='#000000' variant='h5'>2</Typography>
					</Box>
					<Typography>Ring up the items and click Pay</Typography>
				</Stack>
				<Stack direction='row' sx={{ alignItems: 'center' }}>
					<Box
						className='center'
						sx={{
							bgcolor     : '#ffffff',
							borderRadius: '50%',
							height      : 30,
							mr          : 1,
							width       : 30,
						}}>
						<Typography color='#000000' variant='h5'>3</Typography>
					</Box>
					<Typography>Click the "Invoiss" tender</Typography>
				</Stack>
			</Stack>
		</ResponsiveModalContainer>
	);
}
