import FormGraphQLAutocomplete from '@/components/form/fields/formGraphQLAutocomplete';
import { FormGraphQLAutoCompleteProps } from '@/components/form/fields/types';
import { CommercesRead } from '@/data/commerce/commerce.graphql';
import currencyFormat from '@/helpers/currencyFormat';
import usePermissions from '@/providers/auth/usePermissions';
import useUserInfo from '@/providers/auth/useUserInfo';
import type { Order, QueryCommercesReadArgs } from '@/types/schema';
import { Divider, FormControlLabel, MenuItem, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import { toUpper, upperFirst } from 'lodash-es';
import { Fragment, useState } from 'react';

export default function CommerceSelect( props: Omit<FormGraphQLAutoCompleteProps<Order, QueryCommercesReadArgs>, 'query' | 'queryKey'> & {
	client?: string
} ) {
	const { staff } = useUserInfo();
	const isOwner = usePermissions( [ 'OWNER' ] );
	const isAdmin = usePermissions( [ 'ADMIN' ] );
	const [ type, setType ] = useState( 'INVOICE' );
	const options = [ 'INVOICE', 'ORDER', 'ESTIMATE' ];
	
	return (
		<Stack>
			<RadioGroup
				value={type}
				onChange={( event ) => setType( event.target.value )}>
				<Stack direction='row'>
					{options.map( ( option ) => (
						<FormControlLabel
							key={option}
							value={option}
							control={<Radio/>}
							label={upperFirst( option )}
						/>
					) )}
				</Stack>
			</RadioGroup>
			<FormGraphQLAutocomplete<Order>
				queryKey={[ 'order' ]}
				query={CommercesRead}
				variables={{
					options: {
						filter: {
							client: props.client || undefined,
							staff : isOwner || isAdmin || staff.metadata?.viewOtherEntries ? undefined : staff.id,
							type  : toUpper( type ),
						},
					},
				}}
				getOptionLabel={( { number, grandTotal, staff }: Order ) => [
					`#${number}  - `,
					`$${grandTotal}  - `,
					!staff?.user ? '' : `${staff?.user?.firstName} ${staff?.user?.lastName} (staff)`,
				].join( ' ' ).trim()}
				renderOption={( props, option ) => (
					<Fragment key={option.id}>
						<MenuItem {...props}>
							<Stack direction='row' spacing={1} justifyContent='space-between' alignItems='center'>
								{Boolean( option.number ) && (
									<Typography width={200} fontWeight={500}>
										#{option.number}
									</Typography>
								)}
								{Boolean( option.grandTotal ) && (
									<Typography width={100}>
										{currencyFormat( option.grandTotal )}
									</Typography>
								)}
								{Boolean( option.staff?.user ) && (
									<Typography width={100} color='text.secondary'>
										{`${option.staff?.user?.firstName} ${option.staff?.user?.lastName} (staff)`}
									</Typography>
								)}
							</Stack>
						</MenuItem>
						<Divider sx={{ my: 1 }}/>
					</Fragment>
				)}
				{...props}
				textFieldProps={{ ...props.textFieldProps }}
			/>
		</Stack>
	);
}
