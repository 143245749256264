import PageLinkComponent from '@/components/page/linkComponent';
import useUserInfo from '@/providers/auth/useUserInfo';
import { isSuperAdmin } from '@/utils/isSuperAdmin';
import { PeopleAlt as PeopleAltIcon } from '@mui/icons-material';
import { AppBar, Button, Container, Stack } from '@mui/material';
import { useRouter } from 'next/router';
import type { ReactNode } from 'react';
import { Fragment, useEffect } from 'react';

const adminMenuItems = [ {
	name: 'Accounts',
	href: '/admin/accounts',
	icon: <PeopleAltIcon/>,
}, {
	name: 'Staff',
	href: '/admin/staffs',
}, {
	name: 'Stores',
	href: '/admin/stores',
}, {
	name: 'Commerces',
	href: '/admin/commerces',
}, {
	name: 'Metered',
	href: '/admin/metered',
}, {
	name: 'House',
	href: '/admin/houseAccounts',
}, {
	name: 'Meters',
	href: '/admin/meters',
}, {
	name: 'Feedbacks',
	href: '/admin/feedbacks',
} ];

export default function AdminLayout( { children }: { children: ReactNode } ) {
	const { user } = useUserInfo();
	const router = useRouter();
	
	useEffect( () => {
		if ( !isSuperAdmin( user ) ) {
			router.replace( '/' ).then();
		}
	}, [ user ] );
	
	if ( !isSuperAdmin( user ) ) return null;
	
	return (
		<Fragment>
			<AppBar
				color='inherit'
				position='static'
				sx={{ py: 1, bgcolor: 'transparent' }}>
				<Container maxWidth='xl'>
					<Stack direction='row' alignItems='center' spacing={1}>
						{adminMenuItems.map( ( menuItem, index ) => {
							const selectedRoute = router.route.includes( menuItem.href );
							return (
								<Button
									key={index}
									variant='contained'
									color={selectedRoute ? 'primary' : 'alpha'}
									sx={{
										'height'      : 27,
										'boxShadow'   : 'none !important',
										'border'      : '0px !important',
										'transition'  : '.4s all',
										'borderRadius': '15px !important',
										':hover'      : { bgcolor: !selectedRoute && 'divider' },
									}}
									startIcon={menuItem?.icon}
									component={PageLinkComponent}
									href={menuItem.href}>
									{menuItem.name}
								</Button>
							);
						} )}
					</Stack>
				</Container>
			</AppBar>
			{children}
		</Fragment>
	);
}
