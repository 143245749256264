import { PLATFORM_ENDPOINT } from '@/pages/api/processor/clover';
import { Order, Store } from '@/types/schema';
import { getBrowserTimezone } from '@/utils/timezone';
import axios from 'axios';
import { toLower } from 'lodash-es';
import process from 'process';

export const printAndSendOrder = async (
	invoice: Order,
	store: Store,
) => {
	const timezone = getBrowserTimezone();
	
	if ( invoice.externalId && invoice.gateway?.externalId ) {
		await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/post`, {
			url   : `${PLATFORM_ENDPOINT}/${invoice.gateway.externalId}/print_event`,
			data  : { orderRef: { id: invoice.externalId } },
			config: { headers: { Authorization: `Bearer ${invoice.gateway.externalKey}` } },
		} ).catch( () => null );
	}
	
	// receipt to user email and phone that their customer has made a purchase
	await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/emails/${store.id}/storeOrderReceipt`, {
		orderId: invoice.id,
		timezone,
	} ).catch( () => null );
	if ( store.cell || invoice.company?.cell ) {
		await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/message`, {
			company: invoice.company.id,
			number : store.cell || invoice.company.cell,
			message: `Hi ${invoice.company.name || invoice.company.contact || ''},\n
				You just received an order! ${invoice?.metadata?.customNumber || invoice?.number || invoice?.externalId}\n
				Order details: ${process.env.NEXT_PUBLIC_SERVER_URL}/p/${invoice.id}/${toLower( invoice.type )}\n
				`,
			timezone,
		} ).catch( () => null );
	}
	
	// receipt to client email that their order has been placed
	await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/emails/${store.id}/storeClientReceipt`, {
		orderId: invoice.id,
		timezone,
	} ).catch( () => null );
	if ( invoice.client?.cell ) {
		await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/message`, {
			company: invoice.company.id,
			number : invoice.client.cell,
			message: `Hi ${invoice.client.name || invoice.client.contact || ''},\n
				You have an order from ${invoice.company.name || invoice.company.contact || ''}\n
				Order details: ${process.env.NEXT_PUBLIC_SERVER_URL}/p/${invoice.id}/${toLower( invoice.type )}\n
				`,
		} ).catch( () => null );
	}
	
	await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/notification/printNotification`, {
		orderId: invoice.id,
	} ).catch( ( e ) => console.log( e ) );
	
};
