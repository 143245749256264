import PageLinkComponent from '@/components/page/linkComponent';
import usePermissions from '@/providers/auth/usePermissions';
import { SecurityRounded as SecurityRoundedIcon } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { useRouter } from 'next/router';

export default function ActivityLogButton() {
	const isOwner = usePermissions( [ 'OWNER' ] );
	const isAdmin = usePermissions( [ 'ADMIN' ] );
	const viewActivity = isOwner || isAdmin;
	
	const router = useRouter();
	const isLogsRoute = router.asPath.split( '/' )[ 2 ] === 'logs';
	
	if ( !viewActivity ) return null;
	
	return (
		<Tooltip title='Activity' placement='bottom'>
			<IconButton component={PageLinkComponent} href='/dashboard/logs' color={isLogsRoute ? 'primary' : 'default'}>
				<SecurityRoundedIcon/>
			</IconButton>
		</Tooltip>
	);
}
