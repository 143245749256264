import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
	.use( initReactI18next )
	.init( {
		resources    : {
			en: {
				common    : require( '../locales/en/common.json' ),
				commerce  : require( '../locales/en/commerce.json' ),
				management: require( '../locales/en/management.json' ),
				settings  : require( '../locales/en/settings.json' ),
			},
			es: {
				common    : require( '../locales/es/common.json' ),
				commerce  : require( '../locales/es/commerce.json' ),
				management: require( '../locales/es/management.json' ),
				settings  : require( '../locales/es/settings.json' ),
			},
			fr: {
				common    : require( '../locales/fr/common.json' ),
				commerce  : require( '../locales/fr/commerce.json' ),
				management: require( '../locales/fr/management.json' ),
				settings  : require( '../locales/fr/settings.json' ),
			},
			cn: {
				common    : require( '../locales/cn/common.json' ),
				commerce  : require( '../locales/cn/commerce.json' ),
				management: require( '../locales/cn/management.json' ),
				settings  : require( '../locales/cn/settings.json' ),
			},
		},
		lng          : 'en',
		fallbackLng  : 'en',
		interpolation: {
			escapeValue: false,
		},
	} );

export default i18n;
