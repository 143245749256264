import { menuItem } from '@/baseline/navigation/menuItems';
import CustomCheckBox from '@/components/customCheckBox';
import AsyncLoadingButton from '@/components/form/asyncLoading/asyncLoadingButton';
import { mutateGraphQL } from '@/data/apollo';
import { StaffTableWrite } from '@/data/management/staff.graphql';
import useUserInfo from '@/providers/auth/useUserInfo';
import { usePopper } from '@/providers/popper';
import { MutationStaffTableWriteArgs } from '@/types/schema';
import { Box, ListItemButton, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function TabsMenu( {
	items,
	tabs,
	setTabs,
}: {
	items: menuItem[],
	tabs: Record<string, boolean>,
	setTabs: ( tabs: Record<string, boolean> ) => void
} ) {
	const { staff } = useUserInfo();
	const queryClient = useQueryClient();
	const { closePopper } = usePopper();
	const [ menuTabs, setMenuTabs ] = useState<Record<string, boolean>>( tabs );
	const { t } = useTranslation();
	
	return (
		<Box sx={{ width: 300, p: 1 }}>
			<Typography variant='h5'>
				{t( 'common:modify-tabs' )}
			</Typography>
			<Typography gutterBottom color='text.secondary'>
				{t( 'common:modify-tabs-des' )}
			</Typography>
			<Stack spacing={.5}>
				{items?.filter( ( tab ) => tab.name !== 'Dashboard' )?.map( ( menuItem, index ) => (
					<ListItemButton
						key={index}
						disableGutters
						sx={{ py: .5 }}
						onClick={() => {
							if ( !menuTabs[ menuItem.name ] ) {
								setMenuTabs( { ...menuTabs, [ menuItem.name ]: true } );
							} else {
								setMenuTabs( { ...menuTabs, [ menuItem.name ]: false } );
							}
						}}>
						<ListItemIcon sx={{ minWidth: 40, alignSelf: 'start' }}>
							<CustomCheckBox checked={menuTabs[ menuItem.name ]}/>
						</ListItemIcon>
						<ListItemText primary={t( `common:${menuItem.name.toLowerCase().split( ' ' ).join( '-' )}` )}/>
					</ListItemButton>
				) )}
				<AsyncLoadingButton
					color='primary'
					variant='contained'
					onClick={async () => {
						const { staffTableWrite } = await mutateGraphQL<MutationStaffTableWriteArgs>( {
							mutation : StaffTableWrite,
							variables: {
								id   : staff.id,
								input: { selectedTabsData: { menuTabs } },
							},
						} );
						
						setTabs( staffTableWrite.selectedTabsData?.menuTabs );
						await queryClient.invalidateQueries( [ 'user' ] );
						closePopper();
					}}>
					Apply
				</AsyncLoadingButton>
			</Stack>
		</Box>
	);
}
