import { combineReducers } from '@reduxjs/toolkit';
import auth from './authReducer';
import data from './dataReducer';
import main from './mainReducer';

export const rootReducer = combineReducers( {
	main,
	data,
	auth,
} );
