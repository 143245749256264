import PageLink from '@/components/page/link';
import StyledImage from '@/components/styledImage';
import { useDismissibleMessage } from '@/hooks/useDismissibleMessage';
import usePermissions from '@/providers/auth/usePermissions';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useMenu } from '@/providers/menu';
import { Close as CloseIcon, Notifications as NotificationsIcon } from '@mui/icons-material';
import {
	Badge,
	Box,
	Button,
	Chip,
	IconButton,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Stack,
	Tab,
	Tabs,
	Tooltip,
	Typography,
	useTheme,
} from '@mui/material';
import { SyntheticEvent, useEffect, useState } from 'react';

const initialMessages = [ {
	id           : '186',
	title        : 'Add order type to invocies and orders',
	content      : 'You can now assign clover order types to invocies and orders.',
	learnMoreLink: '',
}, {
	id           : '187',
	title        : 'Boost your cash flow.',
	content      : 'Encourage your clients to pay in advance, allowing them to use their store credits to pay future invoices.',
	learnMoreLink: 'https://www.invoiss.com/dashboard/settings/doc?tab=payments',
}, {
	id           : '188',
	title        : 'Join us in enhancing your experience',
	content      : 'Book a session with us to improve Invoiss',
	learnMoreLink: 'https://calendly.com/invoiss/invoiss-introduction',
} ];

function MenuContent( { title, message, learnMoreLink, onIndividualMessageDismiss } ) {
	const theme = useTheme();
	const isDarkMode = theme.palette.mode === 'dark';
	const isOwner = usePermissions( [ 'OWNER' ] );
	
	return (
		<ListItem>
			<ListItemAvatar>
				<StyledImage
					alt='Invoiss Logo'
					sx={{ height: 30 }}
					src={`/images/invoiss-logo-${isDarkMode ? 'light' : 'dark'}.png`}
				/>
			</ListItemAvatar>
			<ListItemText
				primary={(
					<Stack direction='row' alignItems='center' spacing={1}>
						<Typography variant='h6' fontWeight='500'>{title}</Typography>
						<Tooltip title='System Notification'>
							<Box>
								<Chip
									color='primary'
									label='System'
									variant='alpha'
									sx={{ zoom: .8 }}
								/>
							</Box>
						</Tooltip>
					</Stack>
				)}
				secondary={(
					<Box>
						<Box>
							{message}
						</Box>
						{learnMoreLink && isOwner && <PageLink href={learnMoreLink}>Let's do it</PageLink>}
					</Box>
				)}
			/>
			<Tooltip title='Dismiss Notification'>
				<IconButton onClick={onIndividualMessageDismiss}>
					<CloseIcon sx={{ fontSize: 17 }}/>
				</IconButton>
			</Tooltip>
		</ListItem>
	);
}

function MenuSubContent( { closeMenu, updateMessageCount } ) {
	const [ value, setValue ] = useState( 0 );
	const { messages, dismissMessage } = useDismissibleMessage( initialMessages, 'dismissedMessageIds' );
	
	useEffect( () => {
		updateMessageCount( messages.length );
	}, [ messages ] );
	
	return (
		<Box sx={{ width: 500, p: 1 }}>
			<Stack p={1} direction='row' alignItems='center' justifyContent='space-between'>
				<Typography variant='h4' fontWeight='500'>Notifications</Typography>
				<Stack direction='row' spacing={1} alignItems='center'>
					{Boolean( messages.length ) && (
						<Button
							variant='text'
							onClick={() => {
								messages.forEach( ( message ) => dismissMessage( message.id ) );
							}}>
							Dismiss All
						</Button>
					)}
					<IconButton onClick={closeMenu}>
						<CloseIcon/>
					</IconButton>
				</Stack>
			</Stack>
			<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
				<Tabs value={value} onChange={( event: SyntheticEvent, newValue: number ) => setValue( newValue )}>
					<Tab label='All'/>
				</Tabs>
			</Box>
			{messages.length === 0 && (
				<Box sx={{ p: 2, textAlign: 'center' }}>
					<Typography variant='h6' fontWeight='500'>No notifications</Typography>
				</Box>
			)}
			{messages.length !== 0 && messages.map( ( message ) => (
				<MenuContent
					key={message.id}
					title={message.title}
					message={message.content}
					learnMoreLink={message.learnMoreLink}
					onIndividualMessageDismiss={() => dismissMessage( message.id )}
				/>
			) )}
		</Box>
	);
}

export default function MenuContentWrapper() {
	const { showMenu } = useMenu();
	const { staff } = useUserInfo();
	
	const [ messageCount, setMessageCount ] = useState( initialMessages.length );
	
	const localStorageMessagesIds = JSON.parse( localStorage.getItem( 'dismissedMessageIds' ) );
	
	useEffect( () => {
		const remainingMessages = initialMessages.filter( ( message ) =>
			!localStorageMessagesIds?.includes( message.id ),
		);
		setMessageCount( remainingMessages.length );
	}, [] );
	
	if ( !staff ) return null;
	
	return (
		<Tooltip title='Notifications'>
			<IconButton
				onClick={( e ) => showMenu( ( { closeMenu } ) => (
					<MenuSubContent
						closeMenu={closeMenu}
						updateMessageCount={setMessageCount}
					/>
				), e.currentTarget, {
					anchorOrigin   : { vertical: 'bottom', horizontal: 'center' },
					transformOrigin: { vertical: 'top', horizontal: 'center' },
				} )}>
				<Badge
					badgeContent={messageCount}
					color='error'>
					<NotificationsIcon/>
				</Badge>
			</IconButton>
		</Tooltip>
	);
}
