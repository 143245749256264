import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';

export default function InitialSnack() {
	const router = useRouter();
	const { enqueueSnackbar } = useSnackbar();
	
	useEffect( () => {
		if ( router.query.success ) {
			enqueueSnackbar( decodeURIComponent( atob( router.query.success as string ) ), { variant: 'success' } );
		} else if ( router.query.error ) {
			enqueueSnackbar( decodeURIComponent( atob( router.query.error as string ) ), { variant: 'error' } );
		}
	}, [] );
	
	return null;
}
