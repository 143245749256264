import CreateRoomButton from '@/components/createRoomButton';
import MinimizeChatButton from '@/components/minimizeChatButton';
import PageWrapper from '@/components/page/wrapper';
import { CheckPermissions, permissions } from '@/providers/auth/usePermissions';
import { Paper, Stack, Theme, useMediaQuery } from '@mui/material';
import React, { ReactNode } from 'react';
import ClientRoomSideBar from '../../client/chat/clientSideBar';
import RoomSideBar from './sideBar';

export default function ChatLayout( { children, clientView }: { children?: ReactNode, clientView?: boolean } ) {
	const isMobile = useMediaQuery<Theme>( ( { breakpoints } ) => breakpoints.down( 'sm' ) );
	
	return (
		<CheckPermissions redirect='/' permissions={permissions.chat.read}>
			<PageWrapper
				hideBack
				primary='Chat'
				actions={isMobile
					? <CreateRoomButton clientView={clientView}/>
					: <MinimizeChatButton/>}>
				<Stack direction='row' spacing={1}>
					{isMobile
						? <RoomSideBar/> : (
							<Paper variant='borderless'>
								<Stack
									sx={{
										'height'          : '75vh',
										'width'           : { xs: '100%', sm: 266 },
										'.MuiDrawer-paper': { borderRadius: 4 },
									}}>
									{clientView ? <ClientRoomSideBar/> : <RoomSideBar/>}
								</Stack>
							</Paper>
						)}
					{children}
				</Stack>
			</PageWrapper>
		</CheckPermissions>
	);
}
