import HelpButton from '@/baseline/navigation/desktop/helpButton';
import PageLinkComponent from '@/components/page/linkComponent';
import StyledImage from '@/components/styledImage';
import { isReactNativeWebView } from '@/helpers/reactNativePostMessage';
import useGetDeviceInfo from '@/hooks/useGetDeviceInfo';
import useResponsiveDrawer from '@/hooks/useResponsiveDrawer';
import usePermissions from '@/providers/auth/usePermissions';
import useUserInfo from '@/providers/auth/useUserInfo';
import {
	AccountBalanceRounded as AccountBalanceRoundedIcon,
	AccountCircleRounded as AccountCircleRoundedIcon,
	ApartmentRounded as ApartmentRoundedIcon,
	AssignmentRounded as AssignmentRoundedIcon,
	CircleNotificationsRounded as CircleNotificationsRoundedIcon,
	CreditCardRounded as CreditCardRoundedIcon,
	GavelRounded as GavelRoundedIcon,
	ImportantDevices as ImportantDevicesIcon,
	Info as InfoIcon,
	LanRounded as LanRoundedIcon,
	LockRounded as LockRoundedIcon,
	Menu as MenuIcon,
	MonetizationOn as MonetizationOnIcon,
	MoreVert as MoreVertIcon,
	SecurityRounded as SecurityRoundedIcon,
	SettingsBrightnessRounded as SettingsBrightnessRoundedIcon,
	StoreRounded as StoreRoundedIcon,
	WidgetsRounded as WidgetsRoundedIcon,
} from '@mui/icons-material';
import {
	Box,
	Container,
	Grid,
	IconButton,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Stack,
	Theme,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import Head from 'next/head';
import { useRouter } from 'next/router';
import type { ComponentType } from 'react';
import { Fragment, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default function SettingsLayout( { children } ) {
	const { t } = useTranslation();
	const isMobile = useMediaQuery<Theme>( ( { breakpoints } ) => breakpoints.down( 'sm' ) );
	const { user, staff } = useUserInfo();
	const isOwner = usePermissions( [ 'OWNER' ] );
	const isAdmin = usePermissions( [ 'ADMIN' ] );
	const router = useRouter();
	const theme = useTheme();
	const isDarkMode = theme.palette.mode === 'dark';
	const { isCloverDevice } = useGetDeviceInfo();
	
	const tabs = useMemo( () => {
		const tabs: {
			id?: string,
			name?: string,
			href?: string,
			target?: string,
			icon?: ComponentType
		}[] = [];
		
		if ( user ) {
			tabs.unshift(
				{ id: 'profile', name: t( 'settings:profile' ), icon: AccountCircleRoundedIcon },
				{ id: 'preferences', name: t( 'settings:preferences' ), icon: SettingsBrightnessRoundedIcon },
				{ id: 'security', name: t( 'settings:security' ), icon: LockRoundedIcon },
			);
		}
		
		if ( staff ) {
			tabs.unshift( { id: 'companyProfile', name: t( 'settings:company-profile' ), icon: StoreRoundedIcon } );
			tabs.push( { id: 'third-party', name: 'Third Party', icon: LanRoundedIcon } );
			if ( isOwner ) {
				!isMobile && tabs.push( {
					id  : 'subscription',
					name: t( 'settings:subscription' ),
					icon: CreditCardRoundedIcon,
				} );
				tabs.push( { id: 'devices', name: 'Devices', icon: ImportantDevicesIcon } );
				tabs.push( { id: 'notifications', name: 'Notifications', icon: CircleNotificationsRoundedIcon } );
				tabs.push( { id: 'doc', name: t( 'settings:doc-settings' ), icon: AssignmentRoundedIcon } );
				!isMobile && tabs.push( { id: 'gateway', name: t( 'settings:gateway' ), icon: AccountBalanceRoundedIcon } );
				tabs.push( { id: 'widget', name: t( 'settings:invoiss-widget' ), icon: WidgetsRoundedIcon } );
			}
			if ( isAdmin ) {
				tabs.push( { id: 'referral', name: t( 'settings:refer-earn' ), icon: MonetizationOnIcon } );
			}
		}
		
		if ( user ) {
			tabs.unshift( { id: 'companies', name: t( 'common:companies' ), icon: ApartmentRoundedIcon } );
		}
		
		tabs.push(
			{ id: 'about', name: t( 'settings:about' ), icon: InfoIcon },
			!isCloverDevice && {
				id    : 'terms',
				name  : t( 'settings:terms-of-service' ),
				href  : '/terms',
				icon  : GavelRoundedIcon,
				target: '_blank',
			},
			!isCloverDevice && {
				id    : 'privacy',
				name  : t( 'settings:privacy-policy' ),
				href  : '/privacy',
				icon  : SecurityRoundedIcon,
				target: '_blank',
			},
			...isReactNativeWebView() ? [ {
				id  : 'more-settings',
				name: t( 'settings:more-settings' ),
				icon: MoreVertIcon,
			} ] : [],
			{ id: 'logout', name: t( 'settings:log-out' ), href: '/logout', icon: LockRoundedIcon },
		);
		
		return tabs;
	}, [ user, staff, isOwner ] );
	
	const current = useMemo( () => {
		const route = router.route.split( '/' ).pop();
		return tabs.findIndex( ( tab ) => tab.id === route );
	}, [ router.route ] );
	
	useEffect( () => {
		if ( current === -1 || router.asPath === '/dashboard/settings' ) {
			router.replace( `/dashboard/settings/${tabs[ 0 ].id}` ).then();
		}
	}, [ current, router.asPath ] );
	
	useEffect( () => {
		document.body.style.overflow = 'hidden';
		
		return () => {
			// Reset overflow style when component unmounts
			document.body.style.overflow = 'auto';
		};
	}, [] );
	
	const { toggleDrawer, drawer } = useResponsiveDrawer( () => (
		<List
			sx={{
				width : { xs: '100%', sm: 280 },
				height: '100%',
			}}>
			{tabs.map( ( { id, name, icon: Icon, ...props }, index ) => (
				<ListItemButton
					key={id}
					selected={current === index}
					component={PageLinkComponent}
					href={`/dashboard/settings/${id}`}
					sx={{
						'.MuiListItemIcon-root'   : { minWidth: 30 },
						'borderRadius'            : 2,
						'm'                       : .5,
						'py'                      : .6,
						'transition'              : '0.3s',
						'.MuiListItemText-primary': { fontWeight: 500, fontSize: { sm: '1.7rem !important' } },
						'SVG'                     : { fontSize: '24px !important' },
						'&.Mui-selected'          : {
							'color'                   : 'primary.main',
							'.MuiListItemText-primary': { fontWeight: 'bold' },
							'SVG'                     : { color: 'primary.main' },
						},
						':last-of-type': {
							'color'     : 'error.main',
							'transition': 'all 0.2s ease-in-out',
							':hover'    : {
								bgcolor: 'error.main',
								color  : 'white',
							},
						},
					}}
					onClick={() => isMobile && toggleDrawer()}
					{...props}>
					{Icon && <ListItemIcon><Icon/></ListItemIcon>}
					<ListItemText>{name}</ListItemText>
				</ListItemButton>
			) )}
		</List>
	), { breakpoint: [ 'sm', 'md' ] } );
	
	return (
		<Fragment>
			<Head><title>{t( 'settings:settings' )} | Invoiss</title></Head>
			<Container disableGutters maxWidth={false}>
				<Grid container sx={{ height: '100%' }}>
					{toggleDrawer && (
						<Grid item xs={12}>
							<Stack p={2} px={3} direction='row' alignItems='center' justifyContent='space-between'>
								<Box>
									<IconButton onClick={toggleDrawer as any}><MenuIcon/></IconButton>
								</Box>
								<StyledImage
									alt='Invoiss Logo'
									sx={{ height: 30 }}
									src={`/images/invoiss-logo${isDarkMode ? 'white' : 'black'}.png`}
								/>
								<HelpButton/>
							</Stack>
						</Grid>
					)}
					{toggleDrawer ? drawer : (
						<Grid
							item
							sm={4}
							xs={12}
							sx={{
								display       : 'flex',
								justifyContent: 'end',
								borderRight   : 1,
								borderColor   : 'divider',
							}}>
							<Box
								sx={{
									position     : 'sticky',
									top          : 0,
									maxHeight    : 'calc(100vh - 106px)',
									display      : 'flex',
									flexDirection: 'column',
								}}>
								<Box
									ref={( el: any ) => {
										if ( !el ) return;
										const handleScroll = () => {
											el.setAttribute( 'data-scrolled', ( el.scrollTop > 0 ).toString() );
											el.setAttribute(
												'data-scrolled-bottom',
												( Math.ceil( el.scrollHeight - el.scrollTop ) <= el.clientHeight ).toString(),
											);
										};
										el.addEventListener( 'scroll', handleScroll );
										handleScroll();
									}}
									sx={{
										'flex'                                 : 1,
										'overflowY'                            : 'auto',
										'overflowX'                            : 'hidden',
										'pt'                                   : 4,
										'pb'                                   : 12,
										'position'                             : 'relative',
										'&[data-scrolled="true"] + .top-shadow': {
											opacity: 1,
										},
										'&[data-scrolled-bottom="true"] ~ .bottom-shadow': {
											opacity: 0,
										},
									}}>
									{drawer}
								</Box>
								<Box
									className='top-shadow'
									sx={{
										position       : 'absolute',
										top            : 0,
										left           : 0,
										right          : 0,
										height         : '40px',
										pointerEvents  : 'none',
										backgroundImage: ( theme ) =>
											`linear-gradient(to bottom, ${theme.palette.background.paper}, rgba(255,255,255,0))`,
										opacity   : 0,
										transition: 'opacity 0.2s',
										zIndex    : 1,
									}}
								/>
								<Box
									className='bottom-shadow'
									sx={{
										position       : 'absolute',
										bottom         : 0,
										left           : 0,
										right          : 0,
										height         : '40px',
										pointerEvents  : 'none',
										backgroundImage: ( theme ) =>
											`linear-gradient(to top, ${theme.palette.background.paper}, rgba(255,255,255,0))`,
										opacity   : 1,
										transition: 'opacity 0.2s',
										zIndex    : 1,
									}}
								/>
							</Box>
						</Grid>
					)}
					<Grid
						item
						sm={!toggleDrawer && 8}
						xs={12}
						sx={{
							bgcolor : 'background.paper',
							height  : 'calc(100vh - 106px)',
							overflow: 'auto',
						}}>
						{current !== -1 && children}
					</Grid>
				</Grid>
			</Container>
		</Fragment>
	);
}
