export default function suppressConsoleWarnings( warningPatterns: RegExp[] ): void {
	try {
		const originalConsoleError = console.error;
		const originalConsoleWarn = console.warn;
		
		console.error = ( ...args: any[] ): void | null => {
			try {
				if ( Array.isArray( warningPatterns ) && args.length > 0 && typeof args[ 0 ] === 'string' ) {
					if ( warningPatterns.some( ( pattern ) => pattern.test( args[ 0 ] ) ) ) {
						return null;
					}
				}
				return originalConsoleError.call( console, ...args );
			} catch ( e ) {
				return originalConsoleError.call( console, ...args );
			}
		};
		
		console.warn = ( ...args: any[] ): void | null => {
			try {
				if ( Array.isArray( warningPatterns ) && args.length > 0 && typeof args[ 0 ] === 'string' ) {
					if ( warningPatterns.some( ( pattern ) => pattern.test( args[ 0 ] ) ) ) {
						return null;
					}
				}
				return originalConsoleWarn.call( console, ...args );
			} catch ( e ) {
				return originalConsoleWarn.call( console, ...args );
			}
		};
	} catch ( e ) {
		// In case the function setup fails, do nothing
		return null;
	}
}
export const warningsToSuppress: RegExp[] = [
	/:first-child/,
	/Some other warning/,
	/Yet another warning/,
	/has no namespace in front of it/,
	/is missing in current namespace/,
	/MUI: The value provided to Autocomplete is invalid/,
	/Warning: validateDOMNesting/,
	/If you intentionally want it to appear in the DOM as a custom attribute, spell it as lowercase/,
];
