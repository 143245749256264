import { ResponsiveModalContainer } from '@/providers/modal/responsiveModal';
import { Typography } from '@mui/material';

export default function NoUserMatchMessage() {
	return (
		<ResponsiveModalContainer title="You're logged in with another user">
			<Typography color='text.secondary'>
				Please make sure you're logged in with the email on file. This link is
				for the client to view.
			</Typography>
		</ResponsiveModalContainer>
	);
}
