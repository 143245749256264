import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

type State = {
	idToken: string | null
};

const initialState: State = {
	idToken: null,
};

const authSlice = createSlice( {
	name    : 'auth',
	initialState,
	reducers: {
		setIdToken( state, { payload }: PayloadAction<State['idToken']> ) {
			state.idToken = payload;
		},
	},
} );

export default authSlice.reducer;
export const setIdToken = authSlice.actions.setIdToken;
