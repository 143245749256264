import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

type State = Record<string, any>;

const initialState: State = {};

const dataSlice = createSlice( {
	name    : 'data',
	initialState,
	reducers: {
		setData( state, { payload }: PayloadAction<{ key: string, data: any }> ) {
			state[ payload.key ] = payload.data;
		},
	},
} );

export default dataSlice.reducer;
export const setData = dataSlice.actions.setData;
