import { CloseFullscreenRounded as CloseFullscreenRoundedIcon } from '@mui/icons-material';
import { Button, Theme, Tooltip, useMediaQuery } from '@mui/material';
import { useAtom } from 'jotai/index';
import { useRouter } from 'next/router';
import React from 'react';
import { chatSettingsAtom } from '../utils/atoms';

export default function MinimizeChatButton() {
	const isMobile = useMediaQuery<Theme>( ( { breakpoints } ) => breakpoints.down( 'sm' ) );
	const router = useRouter();
	const [ , setChatSettings ] = useAtom( chatSettingsAtom );
	if ( !router.query.id || isMobile ) return null;
	
	return (
		<Tooltip title='Minimize the chat box to a small modal window and use chat anywhere in the app.'>
			<Button
						startIcon={<CloseFullscreenRoundedIcon/>}
						variant='contained'
						color='alpha'
						onClick={() => setChatSettings( ( prevSettings ) => ( {
							...prevSettings,
							chatRoomID: router.query.id as string,
							windowChat: true,
						} ) )}>
				Minimize Chat
			</Button>
		</Tooltip>
	);
	
}
