import { ApolloProvider } from '@apollo/client';
import { apolloClient } from '../../data/apollo';

export default function Apollo( { children } ) {
	return (
		<ApolloProvider client={apolloClient}>
			{children}
		</ApolloProvider>
	);
}
