import useUserInfo from '@/providers/auth/useUserInfo';
import { useModal } from '@/providers/modal';
import { Button, Stack } from '@mui/material';
import { differenceInDays } from 'date-fns';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from 'react-use';
import FeedbackModal from '../../modals/feedbackModal';

export default function FeedbackSnack() {
	const { showModal } = useModal();
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const { t } = useTranslation();
	const { staff } = useUserInfo();
	const [ hasShownSnackbar, setHasShownSnackbar ] = useLocalStorage( 'hasShownFeedbackSnackBar', false );
	
	useEffect( () => {
		if ( hasShownSnackbar || !staff?.company?.createdAt ) {
			return;
		}
		
		const companyCreatedAtDifferenceInDays = differenceInDays( new Date(), new Date( staff.company.createdAt ) );
		
		if ( companyCreatedAtDifferenceInDays > 20 ) {
			enqueueSnackbar( 'We would love to hear from you!', {
				variant         : 'info',
				persist         : true,
				preventDuplicate: true,
				action          : (
					<Stack direction='row' alignItems='center' spacing={1} ml={1}>
						<Button
							variant='contained'
							onClick={() => {
								closeSnackbar();
								setHasShownSnackbar( true );
								showModal( FeedbackModal, { maxWidth: 'sm' } );
							}}>
							{t( 'Give Feedback' )}
						</Button>
						<Button
							variant='contained'
							onClick={() => {
								closeSnackbar();
								setHasShownSnackbar( true );
							}}>
							{t( 'Not Now' )}
						</Button>
					</Stack>
				),
			} );
		}
	}, [ hasShownSnackbar, staff ] );
	
	return null;
}
