import { useModal } from '@/providers/modal';
import { SearchRounded as SearchRoundedIcon } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import GlobalSearchModal from '../../../modals/globalSearch';

export default function GlobalSearchButton() {
	const { showModal, closeModal } = useModal();
	const [ isModalOpen, setIsModalOpen ] = useState( false );
	
	useEffect( () => {
		const handleKeyDown = ( event ) => {
			if ( event.ctrlKey && event.key === 'b' ) {
				if ( isModalOpen ) {
					closeModal();
					setIsModalOpen( false );
				} else {
					openModal();
					setIsModalOpen( true );
				}
			}
		};
		
		window.addEventListener( 'keydown', handleKeyDown );
		
		return () => {
			window.removeEventListener( 'keydown', handleKeyDown );
		};
	}, [ showModal, closeModal, isModalOpen ] );
	
	const openModal = () => {
		showModal( GlobalSearchModal, {
			maxWidth: 'md',
			sx      : { '.MuiDialog-paper': { borderRadius: 3 } },
		} );
		setIsModalOpen( true );
	};
	
	return (
		<Tooltip title='Search | Ctrl + B'>
			<IconButton
				onClick={() => {
					openModal();
				}}>
				<SearchRoundedIcon/>
			</IconButton>
		</Tooltip>
	);
}
