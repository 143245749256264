import AddressFormModal from '@/pages/p/store/addressModal';
import { useGetStoreAtom } from '@/pages/p/store/context';
import { useModal } from '@/providers/modal';
import {
	LocalShippingRounded as LocalShippingRoundedIcon,
	PinDropRounded as PinDropRoundedIcon,
} from '@mui/icons-material';
import {
	Box,
	Fade,
	ListItemButton,
	ListItemButtonProps,
	ListItemIcon,
	ListItemText,
	Stack,
	Typography,
} from '@mui/material';

export default function AddressButton( { ...props }: ListItemButtonProps ) {
	const { showModal } = useModal();
	
	const { atomCustomerAddress, atomStoreType, atomStoreLocation } = useGetStoreAtom();
	
	const currentAddress = atomStoreType === 'PICKUP' ? atomStoreLocation?.address : atomCustomerAddress;
	
	if ( !atomCustomerAddress && !atomStoreLocation ) return null;
	
	return (
		<Fade in>
			<Box>
				<ListItemButton
					onClick={() => showModal( AddressFormModal, { maxWidth: 'xs' } )}
					{...props}>
					<ListItemIcon sx={{ minWidth: 30 }}>
						{atomStoreType === 'PICKUP' ? <PinDropRoundedIcon/> : <LocalShippingRoundedIcon/>}
					</ListItemIcon>
					<ListItemText
						primaryTypographyProps={{ variant: 'h5' }}
						primary={(
							<Stack direction='row' spacing={1}>
								<Typography color='text.secondary'>
									{atomStoreType === 'PICKUP' ? 'Pick up at' : 'Deliver to'}
								</Typography>
								<Typography variant='h5'>
									{[
										currentAddress?.line1,
										currentAddress?.line2,
										currentAddress?.city ]
										.filter( Boolean )
										.join( ', ' )}
								</Typography>
							</Stack>
						)}
					
					/>
				</ListItemButton>
			</Box>
		</Fade>
	);
}

